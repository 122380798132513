<template>
    <div class="banner">
        <h1>{{ title }}</h1>
        <h2>{{ description }}</h2>
    </div>
</template>

<script>
export default {
    name: 'banner',
    props: [ 'title', 'description' ],
}
</script>

<style lang="scss">
    .banner {
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        padding: 0px 12px;
        box-sizing: border-box;
        position: relative;
        text-align: center;

        h1 {
            margin: 0;
            z-index: 5;
            font-weight: bold;
        }
        h2 {
            margin: 0;
            margin-top: 8px;
            font-weight: 100;
            z-index: 5;
        }
    }

    @media (max-width: 850px) {
        .banner {
        width: 100%;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        padding: 0px 12px;
        box-sizing: border-box;
        position: relative;
        text-align: center;

        h1 {
            margin: 0;
            font-size: 20px;
            z-index: 5;
            font-weight: bold;
        }
        h2 {
            margin: 0;
            margin-top: 8px;
            font-weight: 100;
            z-index: 5;
            font-size: 16px;
        }
    }
}
</style>