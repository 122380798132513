<template>
  <div class="home">
    <div class="home__hero">
      <div class="home__hero-bg"></div>
      <div class="home__text" >
          <h1 v-scroll-reveal.nomobile="{ delay: 200 }">Ne remettez pas à demain ce que l'on peut vous peindre aujourd'hui !</h1>
          <router-link to="/contact" class="button button-contact">Contactez-nous</router-link>
      </div>
    </div>

    <banner title="Envie de changement et de couleurs ?" description="Découvrez notre entreprise, la SARL Lacombe, une palette de savoir-faire !" :style="style"></banner>

    <div class="home-wrapper">
      <div class="home-wrapper__container">
        
          <div class="home-item" v-scroll-reveal.nomobile="{ delay: 300 }">
            <div class="home-item__background"></div>
            <div class="home-item__overlay"></div>
            <router-link class="item-link" to="/entreprise"></router-link>
            <div class="home-item__container">
              <h2 class="home-item__label">Notre entreprise</h2>
              <h3 class="home-item__description"><i>Présentation de notre entreprise, de nos valeurs...</i></h3>
            </div>
          </div>

          <div class="home-item" v-scroll-reveal.nomobile="{ delay: 350 }">
            <div class="home-item__background"></div>
            <div class="home-item__overlay"></div>
            <router-link class="item-link" to="/prestations"></router-link>
            <div class="home-item__container">
              <h2 class="home-item__label">Nos prestations</h2>
              <h3 class="home-item__description"><i>Découvrez la liste de nos prestations et fournisseurs</i></h3>
            </div>
          </div>

          <div class="home-item" v-scroll-reveal.nomobile="{ delay: 400 }">
            <div class="home-item__background"></div>
            <div class="home-item__overlay"></div>
            <router-link class="item-link" to="/references"></router-link>
            <div class="home-item__container">
              <h2 class="home-item__label">Nos références</h2>
              <h3 class="home-item__description"><i>Découvrez quelques unes de nos réalisations</i></h3>
            </div>
          </div>

      </div>
    </div>
  
  </div>
</template>

<script>
// @ is an alias to /src
import banner from '@/components/banner.vue'

export default {
  name: 'home',
    metaInfo() {
            return {
                title: 'Entreprise Lacombe - Le spécialiste de votre peinture dans le Tarn', 
                meta: [
                    { charset: 'utf-8' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { vmid: 'description', name: 'description', content: "Bienvenue sur le site internet de l’entreprise de peinture Lacombe située à Gaillac et à Castres, dans le Tarn. Découvrez nos prestations et contactez nous !"},
                ]
            }
        },

  components: {
    banner
  },
  computed: {
    style () {
        return 'background-color: ' + '#E7AD00';
      }
  }
}
</script>

<style scoped lang="scss">

a {
  text-decoration: none;
  color: inherit;
}
  .home {
    width: 100%;
    box-sizing: border-box;

    .home__hero {
      position: relative;
      width: 100%;
      height: calc(100vh - 90px);
      display: flex;
      align-items: center;
      background-color: rgba(1,1,1, 0.09);
    

      .home__hero-bg {
      background-image: url('../assets/bg2.jpg');
      background-size: cover;
      z-index: -1;
      width: 100%;
      height: calc(100vh - 90px);
      position: absolute;
      }

      .home__text {
        z-index: 10;
        position: absolute;
        padding: 0px 100px;
        width: 65%;
        height: calc(100vh - 90px);
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
        font-size: 30px;
        box-sizing: border-box;
        h1 {
          line-height: 80px;
          font-weight: 600;
        }
      }
    }
  }
  .button {
    cursor: pointer;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-contact {
    align-self: flex-start;
    padding: 16px 24px;
    background-color: #674488;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;

    &:hover {
      background-color: #4E2B6F;
      transition: .3s;
    }
  }

  .home-wrapper {
    display: flex;
    padding: 75px 12px;
    width: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .home-wrapper__container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 1250px;
      margin-right: auto;
      margin-left: auto;
      box-sizing: border-box;


        .home-item {
          height: 500px;
          width: 340px;
          box-sizing: border-box;
          border-radius: 10px;
          display: flex;
          align-items: flex-end;
          position: relative;
      

          .home-item__background {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            position: absolute;
            z-index: 2;

        }
          .home-item__overlay {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            position: absolute;
            z-index: 3;
            background-image: linear-gradient(rgba(33,33,33, 0), rgba(0,0,0, 0.5));
            background-color: rgba(0,0,0,0.2);
          }

        .home-item__container {
          position: absolute;
          box-sizing: border-box;
          z-index: 5;
          padding: 32px;
          display: flex;
          flex-direction: column;
          color: white;

          h2 {
            margin: 0;
            font-size: 20px;
            align-self: flex-start;
            padding: 8px 14px;
            text-transform: uppercase;
            font-weight: 600;
          }

          h3 {
            margin: 0;
            margin-top: 12px;
            font-size: 18px;
            font-weight: 100;
            color: #D9D9D9;
          }
        }
      }

      .home-item:nth-child(1) {
        .home-item__background {
            width: 100%;
            height: 100%;
            background-image: url('../assets/locaux.jpg');
            background-size: cover;
        }

        .home-item__container {
          h2 {
            background-color: #3A633D;
          }
        }
      }

      .home-item:nth-child(2) {
        .home-item__background {
            width: 100%;
            height: 100%;
            background-image: url('../assets/devred1.jpg');
            background-size: cover;
        }

        .home-item__container {
          h2 {
            background-color: #E5AB38;
          }
        }
      }

      .home-item:nth-child(3) {
        .home-item__background {
            width: 100%;
            height: 100%;
            background-image: url('../assets/boucherie.jpg');
            background-size: cover;
        }

        .home-item__container {
          h2 {
            background-color:#5276B3;
          }
        }
      }
    }

    .item-link {
      width: 100%;
      height: 100%;
      border-radius: 2px;
      position: absolute;
      z-index: 6;
    }

  }

  .hovering {
    background-color: rgba(0,0,0,0.5) !important;
    transition: .3s;
  }

  @media (max-width: 1300px) {
    .home {

    .home__hero {
      .home__text {
        z-index: 10;
        position: absolute;
        padding: 0px 100px;
        width: 70%;
        height: calc(100vh - 90px);
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
        font-size: 22px;
        line-height: 20px;

        h1 {
          line-height: 65px;
        }
      }
    }
  }

  .button-contact {
    align-self: flex-start;
    padding: 14px 24px;
    background-color: #674488;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;

    &:hover {
      background-color: darken;
    }
  }

  .home-wrapper {
    display: flex;
    padding: 75px 12px;
    width: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .home-wrapper__container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 1000px;
      margin-right: auto;
      margin-left: auto;
      box-sizing: border-box;


        .home-item {
          height: 450px;
          width: 300px;
          box-sizing: border-box;
          border-radius: 2px;
          display: flex;
          align-items: flex-end;
          position: relative;

        .home-item__container {
          position: absolute;
          box-sizing: border-box;
          z-index: 5;
          padding: 28px;
          display: flex;
          flex-direction: column;
          color: white;

          h2 {
            margin: 0;
            font-size: 18px;
            align-self: flex-start;
            padding: 8px 14px;
            text-transform: uppercase;
            font-weight: 600;
          }

          h3 {
            margin: 0;
            margin-top: 12px;
            font-size: 16px;
            font-weight: 100;
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .home {
    .home__hero {
      .home__text {
        z-index: 10;
        position: absolute;
        padding: 0px 16px;
        width: 100%;
        height: calc(100vh - 90px);
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
        font-size: 22px;

        h1 {
          line-height: 50px;
        }
      }
    }
  }

  .home-wrapper {
    display: flex;
    padding: 75px 12px;
    width: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .home-wrapper__container {
      display: flex;
      width: 100%;
      flex-direction: column;

        .home-item {
          height: 500px;
          width: 400px;
          box-sizing: border-box;
          border-radius: 2px;
          display: flex;
          align-items: flex-end;
          position: relative;
          margin-bottom: 24px;

        .home-item__container {
          position: absolute;
          box-sizing: border-box;
          z-index: 5;
          padding: 28px;
          display: flex;
          flex-direction: column;
          color: white;

          h2 {
            margin: 0;
            font-size: 18px;
            align-self: flex-start;
            padding: 8px 14px;
            text-transform: uppercase;
            font-weight: 500;
          }

          h3 {
            margin: 0;
            margin-top: 12px;
            font-size: 16px;
            font-weight: 100;
          }
        }
      }
    }
  }
}


@media (max-width: 600px) {
  .home {
    .home__hero {
      .home__text {
        z-index: 10;
        position: absolute;
        padding: 0px 16px;
        width: 100%;
        height: calc(100vh - 90px);
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
        font-size: 18px;
      }

      h1 {
          line-height: 24spx;
        }
    }
  }

  .button-contact {
    align-self: flex-start;
    padding: 8px 16px;
    background-color: #674488;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 12px;

    &:hover {
      background-color: darken;
    }
  }

  .home-wrapper {
    display: flex;
    padding: 75px 12px;
    width: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .home-wrapper__container {
      display: flex;
      width: 100%;
      flex-direction: column;

        .home-item {
          height: 500px;
          width: 100%;
          box-sizing: border-box;
          border-radius: 2px;
          display: flex;
          align-items: flex-end;
          position: relative;
          margin-bottom: 24px;

        .home-item__container {
          position: absolute;
          box-sizing: border-box;
          z-index: 5;
          padding: 32px;
          display: flex;
          flex-direction: column;
          color: white;

          h2 {
            margin: 0;
            font-size: 20px;
            align-self: flex-start;
            padding: 8px 14px;
            text-transform: uppercase;
            font-weight: 500;
          }

          h3 {
            margin: 0;
            margin-top: 12px;
            font-size: 18px;
            font-weight: 100;
          }
        }
      }
    }
  }
}
</style>
