<template>
  <div id="app">
    <div id="header">
      <div class="header__container">
        <router-link to="/"><svg  class="logo" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="212.599px" height="138.84px" viewBox="0 0 212.599 138.84" enable-background="new 0 0 212.599 138.84"
	 xml:space="preserve">
<g>
	<g>
		<path fill="#386539" d="M33.262,90.502h-4.588v-7.42h4.588v1.025h-3.562v2.139h2.884v1.025h-2.884v2.205h3.562V90.502z"/>
		<path fill="#386539" d="M35.432,85.065c0.031,0.045,0.056,0.086,0.075,0.124c0.019,0.038,0.037,0.084,0.054,0.137
			c0.018,0.055,0.026,0.111,0.026,0.174v0.223c0.062-0.121,0.148-0.227,0.259-0.318s0.234-0.168,0.373-0.23
			c0.138-0.062,0.287-0.108,0.447-0.14c0.161-0.031,0.324-0.046,0.489-0.046c0.305,0,0.582,0.054,0.834,0.162
			c0.252,0.109,0.467,0.268,0.645,0.475s0.316,0.461,0.415,0.761c0.098,0.3,0.147,0.642,0.147,1.025v3.091h-1.025v-3.091
			c0-0.452-0.083-0.808-0.249-1.064c-0.165-0.257-0.421-0.386-0.767-0.386c-0.207,0-0.402,0.023-0.587,0.07
			s-0.349,0.109-0.492,0.189c-0.144,0.078-0.258,0.174-0.345,0.284c-0.086,0.11-0.135,0.228-0.145,0.353v3.645h-1.025v-5.437H35.432
			z"/>
		<path fill="#386539" d="M41.909,89.131c0,0.055,0.009,0.109,0.026,0.165c0.017,0.056,0.041,0.106,0.072,0.153
			c0.031,0.046,0.069,0.084,0.116,0.113s0.102,0.044,0.163,0.044l0.124-0.015c0.148-0.042,0.278-0.062,0.389-0.062
			c0.142,0,0.266,0.035,0.373,0.108c0.106,0.072,0.196,0.162,0.27,0.269l0.124,0.182c-0.062,0.062-0.146,0.123-0.251,0.184
			c-0.105,0.061-0.218,0.113-0.337,0.158s-0.24,0.082-0.362,0.109c-0.123,0.027-0.232,0.041-0.329,0.041
			c-0.218,0-0.412-0.036-0.585-0.109c-0.173-0.072-0.319-0.172-0.44-0.3s-0.214-0.28-0.279-0.458s-0.099-0.372-0.099-0.582v-3.087
			h-0.906v-0.56c0-0.286,0.265-0.43,0.793-0.43h0.113v-1.118c0-0.11,0.013-0.214,0.039-0.312c0.025-0.096,0.055-0.179,0.085-0.248
			c0.038-0.082,0.08-0.156,0.125-0.223h0.776v1.906h1.507v0.983h-1.507V89.131z"/>
		<path fill="#386539" d="M45.389,85.065c0.031,0.045,0.057,0.086,0.075,0.124c0.02,0.038,0.037,0.084,0.055,0.137
			c0.017,0.055,0.025,0.111,0.025,0.174v0.379c0.062-0.125,0.148-0.24,0.259-0.348c0.111-0.107,0.235-0.201,0.373-0.282
			c0.139-0.081,0.288-0.146,0.448-0.191c0.16-0.047,0.323-0.069,0.489-0.069c0.279,0,0.544,0.049,0.792,0.149l-0.077,0.44
			c-0.028,0.145-0.09,0.258-0.187,0.339s-0.216,0.122-0.357,0.122c-0.018,0-0.027-0.001-0.031-0.003
			c-0.003-0.001-0.01-0.003-0.018-0.003c-0.009,0-0.022,0-0.039-0.002c-0.018-0.002-0.049-0.003-0.094-0.003
			c-0.207,0-0.402,0.03-0.587,0.091c-0.186,0.061-0.348,0.139-0.487,0.235s-0.253,0.206-0.339,0.329
			c-0.087,0.123-0.135,0.246-0.146,0.37v3.448h-1.025v-5.437H45.389z"/>
		<path fill="#386539" d="M53.592,87.322c0,0.121-0.023,0.229-0.07,0.324c-0.047,0.096-0.104,0.176-0.174,0.244
			c-0.068,0.066-0.146,0.119-0.23,0.154c-0.084,0.037-0.165,0.055-0.24,0.055h-3.78c0.031,0.211,0.1,0.406,0.207,0.586
			c0.107,0.179,0.24,0.335,0.398,0.468c0.159,0.134,0.339,0.237,0.539,0.313s0.409,0.113,0.626,0.113
			c0.159,0,0.312-0.02,0.459-0.059c0.146-0.04,0.285-0.096,0.417-0.168c0.068-0.039,0.136-0.066,0.201-0.084
			c0.065-0.017,0.139-0.025,0.218-0.025c0.11,0,0.211,0.021,0.301,0.062c0.089,0.041,0.174,0.102,0.253,0.181l0.197,0.196
			c-0.259,0.277-0.562,0.495-0.911,0.656c-0.349,0.16-0.728,0.24-1.135,0.24c-0.383,0-0.744-0.073-1.084-0.221
			c-0.341-0.146-0.638-0.346-0.892-0.6c-0.253-0.254-0.453-0.551-0.601-0.891c-0.146-0.34-0.22-0.702-0.22-1.086
			c0-0.383,0.073-0.744,0.22-1.084c0.147-0.34,0.348-0.637,0.601-0.891c0.254-0.254,0.551-0.454,0.892-0.602
			c0.34-0.146,0.701-0.219,1.084-0.219c0.321,0,0.629,0.053,0.922,0.16c0.294,0.107,0.56,0.254,0.798,0.439
			c0.238,0.188,0.442,0.408,0.613,0.663c0.171,0.256,0.295,0.534,0.37,0.834c0.011,0.038,0.016,0.077,0.016,0.119
			C53.586,87.245,53.588,87.285,53.592,87.322z M49.485,87.1h3.045c-0.069-0.162-0.159-0.311-0.27-0.447s-0.237-0.254-0.381-0.352
			c-0.144-0.1-0.301-0.176-0.474-0.23c-0.173-0.056-0.353-0.084-0.539-0.084c-0.203,0-0.397,0.033-0.582,0.097
			s-0.353,0.152-0.502,0.267c-0.15,0.113-0.28,0.25-0.389,0.406c-0.109,0.157-0.191,0.329-0.246,0.516
			c0.027-0.038,0.069-0.076,0.124-0.114C49.328,87.119,49.398,87.1,49.485,87.1z"/>
		<path fill="#386539" d="M55.595,85.065c0.031,0.045,0.057,0.086,0.075,0.124c0.02,0.038,0.037,0.084,0.055,0.137
			c0.017,0.055,0.025,0.111,0.025,0.174v0.213c0.011-0.094,0.043-0.176,0.099-0.249c0.056-0.072,0.124-0.136,0.207-0.188
			c0.083-0.054,0.176-0.099,0.277-0.135c0.102-0.037,0.203-0.066,0.306-0.088c0.102-0.023,0.199-0.039,0.292-0.05
			c0.094-0.011,0.173-0.015,0.238-0.015c0.384,0,0.74,0.072,1.069,0.219c0.33,0.147,0.614,0.348,0.854,0.602
			s0.428,0.551,0.564,0.891s0.205,0.701,0.205,1.084c0,0.384-0.068,0.746-0.205,1.086s-0.324,0.637-0.564,0.891
			s-0.524,0.453-0.854,0.6c-0.329,0.147-0.686,0.221-1.069,0.221c-0.121,0-0.242-0.009-0.365-0.025
			c-0.122-0.018-0.244-0.047-0.364-0.088c-0.121-0.042-0.24-0.098-0.357-0.169c-0.118-0.071-0.229-0.159-0.332-0.267v2.227h-1.025
			v-7.192H55.595z M57.169,89.58c0.249,0,0.477-0.047,0.684-0.142s0.386-0.224,0.536-0.386s0.267-0.353,0.35-0.57
			c0.083-0.217,0.124-0.45,0.124-0.699c0-0.248-0.041-0.48-0.124-0.698s-0.199-0.407-0.35-0.569s-0.329-0.291-0.536-0.387
			c-0.207-0.094-0.435-0.143-0.684-0.143c-0.266,0-0.523,0.053-0.774,0.158c-0.25,0.105-0.465,0.25-0.645,0.433v2.413
			c0.18,0.184,0.395,0.327,0.645,0.433C56.646,89.528,56.903,89.58,57.169,89.58z"/>
		<path fill="#386539" d="M61.824,85.065c0.031,0.045,0.057,0.086,0.075,0.124c0.02,0.038,0.037,0.084,0.055,0.137
			c0.017,0.055,0.025,0.111,0.025,0.174v0.379c0.062-0.125,0.148-0.24,0.259-0.348c0.111-0.107,0.235-0.201,0.373-0.282
			c0.139-0.081,0.288-0.146,0.448-0.191c0.16-0.047,0.323-0.069,0.489-0.069c0.279,0,0.544,0.049,0.792,0.149l-0.077,0.44
			c-0.028,0.145-0.09,0.258-0.187,0.339s-0.216,0.122-0.357,0.122c-0.018,0-0.027-0.001-0.031-0.003
			c-0.003-0.001-0.01-0.003-0.018-0.003c-0.009,0-0.022,0-0.039-0.002c-0.018-0.002-0.049-0.003-0.094-0.003
			c-0.207,0-0.402,0.03-0.587,0.091c-0.186,0.061-0.348,0.139-0.487,0.235s-0.253,0.206-0.339,0.329
			c-0.087,0.123-0.135,0.246-0.146,0.37v3.448h-1.025v-5.437H61.824z"/>
		<path fill="#386539" d="M64.916,83.698c0-0.097,0.018-0.187,0.055-0.272c0.036-0.084,0.086-0.158,0.149-0.223
			c0.064-0.063,0.139-0.113,0.223-0.149c0.085-0.036,0.176-0.055,0.272-0.055s0.187,0.019,0.271,0.055s0.159,0.086,0.223,0.149
			c0.063,0.064,0.114,0.139,0.15,0.223c0.036,0.086,0.055,0.176,0.055,0.272s-0.019,0.188-0.055,0.272
			c-0.036,0.084-0.087,0.158-0.15,0.223c-0.063,0.063-0.138,0.113-0.223,0.149s-0.175,0.055-0.271,0.055s-0.188-0.019-0.272-0.055
			c-0.084-0.036-0.158-0.086-0.223-0.149c-0.063-0.064-0.113-0.139-0.149-0.223C64.934,83.886,64.916,83.795,64.916,83.698z
			 M66.122,85.065v5.437h-1.025v-5.437H66.122z"/>
		<path fill="#386539" d="M67.593,89.338c0.069-0.035,0.137-0.063,0.204-0.086s0.136-0.033,0.205-0.033
			c0.09,0,0.175,0.017,0.256,0.051c0.081,0.035,0.179,0.092,0.293,0.172c0.079,0.055,0.165,0.094,0.259,0.119
			c0.093,0.023,0.189,0.035,0.29,0.035c0.106,0,0.213-0.016,0.318-0.049s0.199-0.081,0.282-0.145
			c0.083-0.064,0.15-0.143,0.202-0.236c0.052-0.093,0.077-0.201,0.077-0.326c0-0.096-0.015-0.18-0.044-0.248
			c-0.029-0.069-0.08-0.133-0.152-0.189s-0.17-0.111-0.293-0.166c-0.122-0.053-0.275-0.111-0.458-0.173
			c-0.19-0.065-0.377-0.141-0.562-0.226c-0.186-0.084-0.35-0.188-0.495-0.308c-0.145-0.121-0.262-0.268-0.352-0.44
			c-0.09-0.172-0.135-0.379-0.135-0.621c0-0.211,0.048-0.406,0.145-0.588s0.224-0.338,0.381-0.469s0.336-0.234,0.536-0.311
			s0.403-0.113,0.611-0.113c0.21,0,0.402,0.037,0.577,0.113c0.174,0.076,0.324,0.162,0.45,0.259s0.227,0.188,0.301,0.274
			s0.119,0.141,0.137,0.16l-0.243,0.207c-0.146,0.125-0.314,0.188-0.508,0.188c-0.068,0-0.143-0.016-0.22-0.045
			c-0.078-0.029-0.168-0.069-0.271-0.121c-0.069-0.035-0.144-0.052-0.223-0.052c-0.135,0-0.246,0.019-0.335,0.057
			c-0.088,0.038-0.158,0.084-0.212,0.138c-0.054,0.053-0.091,0.108-0.111,0.166c-0.021,0.057-0.031,0.102-0.031,0.137
			c0,0.1,0.026,0.184,0.078,0.251s0.119,0.126,0.202,0.177c0.082,0.049,0.177,0.094,0.282,0.131
			c0.104,0.039,0.211,0.074,0.318,0.109c0.207,0.068,0.407,0.148,0.601,0.238s0.365,0.2,0.515,0.331
			c0.15,0.132,0.271,0.289,0.36,0.474c0.09,0.186,0.135,0.405,0.135,0.66c0,0.235-0.046,0.457-0.138,0.666
			c-0.091,0.209-0.22,0.394-0.386,0.554c-0.165,0.161-0.362,0.288-0.59,0.381c-0.229,0.093-0.479,0.14-0.751,0.14
			c-0.187,0-0.369-0.025-0.547-0.075s-0.344-0.123-0.499-0.22s-0.296-0.213-0.422-0.348s-0.232-0.287-0.318-0.461L67.593,89.338z"/>
		<path fill="#386539" d="M77.297,87.322c0,0.121-0.023,0.229-0.07,0.324c-0.046,0.096-0.104,0.176-0.173,0.244
			c-0.069,0.066-0.146,0.119-0.23,0.154c-0.085,0.037-0.165,0.055-0.241,0.055h-3.78c0.031,0.211,0.101,0.406,0.208,0.586
			c0.106,0.179,0.239,0.335,0.398,0.468c0.158,0.134,0.338,0.237,0.538,0.313s0.409,0.113,0.627,0.113
			c0.158,0,0.312-0.02,0.458-0.059c0.146-0.04,0.286-0.096,0.417-0.168c0.069-0.039,0.137-0.066,0.202-0.084
			c0.065-0.017,0.138-0.025,0.218-0.025c0.11,0,0.21,0.021,0.3,0.062c0.09,0.041,0.175,0.102,0.254,0.181l0.196,0.196
			c-0.259,0.277-0.562,0.495-0.911,0.656c-0.349,0.16-0.727,0.24-1.134,0.24c-0.383,0-0.745-0.073-1.085-0.221
			c-0.34-0.146-0.637-0.346-0.891-0.6s-0.454-0.551-0.601-0.891s-0.22-0.702-0.22-1.086c0-0.383,0.073-0.744,0.22-1.084
			s0.347-0.637,0.601-0.891s0.551-0.454,0.891-0.602c0.34-0.146,0.702-0.219,1.085-0.219c0.321,0,0.628,0.053,0.922,0.16
			c0.293,0.107,0.559,0.254,0.797,0.439c0.238,0.188,0.443,0.408,0.614,0.663c0.171,0.256,0.294,0.534,0.37,0.834
			c0.01,0.038,0.016,0.077,0.016,0.119C77.292,87.245,77.293,87.285,77.297,87.322z M73.19,87.1h3.045
			c-0.069-0.162-0.159-0.311-0.27-0.447s-0.237-0.254-0.38-0.352c-0.144-0.1-0.302-0.176-0.475-0.23
			c-0.172-0.056-0.352-0.084-0.538-0.084c-0.204,0-0.398,0.033-0.583,0.097s-0.352,0.152-0.502,0.267
			c-0.15,0.113-0.279,0.25-0.389,0.406c-0.108,0.157-0.19,0.329-0.246,0.516c0.028-0.038,0.069-0.076,0.125-0.114
			C73.033,87.119,73.104,87.1,73.19,87.1z"/>
		<path fill="#386539" d="M85.509,90.502c-0.03-0.037-0.057-0.079-0.077-0.124c-0.018-0.038-0.035-0.083-0.052-0.138
			c-0.018-0.053-0.026-0.111-0.026-0.173v-0.036c-0.106,0.107-0.218,0.195-0.334,0.267c-0.115,0.071-0.233,0.128-0.354,0.171
			s-0.243,0.073-0.367,0.091c-0.125,0.017-0.246,0.026-0.363,0.026c-0.383,0-0.739-0.074-1.069-0.221
			c-0.329-0.146-0.614-0.348-0.854-0.601c-0.239-0.254-0.428-0.551-0.564-0.891c-0.136-0.341-0.204-0.702-0.204-1.085
			s0.068-0.744,0.204-1.085c0.137-0.34,0.325-0.637,0.564-0.891c0.24-0.254,0.525-0.454,0.854-0.601
			c0.33-0.146,0.687-0.221,1.069-0.221c0.117,0,0.238,0.01,0.363,0.026c0.124,0.018,0.246,0.047,0.367,0.089
			c0.121,0.041,0.239,0.097,0.354,0.168c0.116,0.07,0.228,0.159,0.334,0.266v-2.459h1.025v7.42H85.509z M83.935,85.992
			c-0.248,0-0.476,0.047-0.684,0.143c-0.207,0.095-0.386,0.223-0.535,0.386c-0.15,0.162-0.267,0.353-0.35,0.569
			c-0.083,0.218-0.125,0.451-0.125,0.699s0.042,0.481,0.125,0.699c0.083,0.217,0.199,0.407,0.35,0.569
			c0.149,0.163,0.328,0.291,0.535,0.386c0.208,0.096,0.436,0.143,0.684,0.143c0.267,0,0.524-0.053,0.774-0.158
			s0.465-0.249,0.645-0.432v-2.414c-0.18-0.183-0.395-0.326-0.645-0.432S84.201,85.992,83.935,85.992z"/>
		<path fill="#386539" d="M93.069,87.322c0,0.121-0.023,0.229-0.07,0.324c-0.046,0.096-0.104,0.176-0.173,0.244
			c-0.069,0.066-0.146,0.119-0.23,0.154c-0.085,0.037-0.165,0.055-0.241,0.055h-3.78c0.031,0.211,0.101,0.406,0.208,0.586
			c0.106,0.179,0.239,0.335,0.398,0.468c0.158,0.134,0.338,0.237,0.538,0.313s0.409,0.113,0.627,0.113
			c0.158,0,0.312-0.02,0.458-0.059c0.146-0.04,0.286-0.096,0.417-0.168c0.069-0.039,0.137-0.066,0.202-0.084
			c0.065-0.017,0.138-0.025,0.218-0.025c0.11,0,0.21,0.021,0.3,0.062c0.09,0.041,0.175,0.102,0.254,0.181l0.196,0.196
			c-0.259,0.277-0.562,0.495-0.911,0.656c-0.349,0.16-0.727,0.24-1.134,0.24c-0.383,0-0.745-0.073-1.085-0.221
			c-0.34-0.146-0.637-0.346-0.891-0.6s-0.454-0.551-0.601-0.891s-0.22-0.702-0.22-1.086c0-0.383,0.073-0.744,0.22-1.084
			s0.347-0.637,0.601-0.891s0.551-0.454,0.891-0.602c0.34-0.146,0.702-0.219,1.085-0.219c0.321,0,0.628,0.053,0.922,0.16
			c0.293,0.107,0.559,0.254,0.797,0.439c0.238,0.188,0.443,0.408,0.614,0.663c0.171,0.256,0.294,0.534,0.37,0.834
			c0.01,0.038,0.016,0.077,0.016,0.119C93.064,87.245,93.065,87.285,93.069,87.322z M88.963,87.1h3.045
			c-0.069-0.162-0.159-0.311-0.27-0.447s-0.237-0.254-0.38-0.352c-0.144-0.1-0.302-0.176-0.475-0.23
			c-0.172-0.056-0.352-0.084-0.538-0.084c-0.204,0-0.398,0.033-0.583,0.097s-0.352,0.152-0.502,0.267
			c-0.15,0.113-0.279,0.25-0.389,0.406c-0.108,0.157-0.19,0.329-0.246,0.516c0.028-0.038,0.069-0.076,0.125-0.114
			C88.806,87.119,88.877,87.1,88.963,87.1z"/>
		<path fill="#386539" d="M98.269,85.065c0.031,0.045,0.056,0.086,0.075,0.124c0.019,0.038,0.037,0.084,0.055,0.137
			c0.017,0.055,0.025,0.111,0.025,0.174v0.213c0.011-0.094,0.043-0.176,0.099-0.249c0.055-0.072,0.124-0.136,0.207-0.188
			c0.083-0.054,0.175-0.099,0.277-0.135c0.102-0.037,0.203-0.066,0.305-0.088c0.103-0.023,0.2-0.039,0.293-0.05
			s0.173-0.015,0.238-0.015c0.383,0,0.739,0.072,1.069,0.219c0.329,0.147,0.614,0.348,0.854,0.602s0.428,0.551,0.564,0.891
			c0.136,0.34,0.204,0.701,0.204,1.084c0,0.384-0.068,0.746-0.204,1.086c-0.137,0.34-0.324,0.637-0.564,0.891
			s-0.525,0.453-0.854,0.6c-0.33,0.147-0.687,0.221-1.069,0.221c-0.121,0-0.242-0.009-0.365-0.025
			c-0.122-0.018-0.244-0.047-0.365-0.088c-0.12-0.042-0.239-0.098-0.357-0.169c-0.117-0.071-0.228-0.159-0.331-0.267v2.227h-1.025
			v-7.192H98.269z M99.843,89.58c0.248,0,0.477-0.047,0.684-0.142s0.386-0.224,0.536-0.386c0.149-0.162,0.267-0.353,0.35-0.57
			c0.082-0.217,0.124-0.45,0.124-0.699c0-0.248-0.042-0.48-0.124-0.698c-0.083-0.218-0.2-0.407-0.35-0.569
			c-0.15-0.162-0.329-0.291-0.536-0.387c-0.207-0.094-0.436-0.143-0.684-0.143c-0.266,0-0.524,0.053-0.774,0.158
			s-0.465,0.25-0.645,0.433v2.413c0.18,0.184,0.395,0.327,0.645,0.433S99.577,89.58,99.843,89.58z"/>
		<path fill="#386539" d="M108.764,87.322c0,0.121-0.023,0.229-0.069,0.324c-0.047,0.096-0.104,0.176-0.174,0.244
			c-0.069,0.066-0.146,0.119-0.23,0.154c-0.085,0.037-0.165,0.055-0.24,0.055h-3.78c0.03,0.211,0.1,0.406,0.207,0.586
			c0.106,0.179,0.239,0.335,0.398,0.468c0.159,0.134,0.338,0.237,0.539,0.313c0.2,0.076,0.408,0.113,0.626,0.113
			c0.159,0,0.312-0.02,0.458-0.059c0.147-0.04,0.286-0.096,0.417-0.168c0.069-0.039,0.137-0.066,0.202-0.084
			c0.065-0.017,0.138-0.025,0.218-0.025c0.11,0,0.21,0.021,0.3,0.062c0.09,0.041,0.175,0.102,0.254,0.181l0.197,0.196
			c-0.259,0.277-0.563,0.495-0.912,0.656c-0.349,0.16-0.727,0.24-1.134,0.24c-0.383,0-0.745-0.073-1.085-0.221
			c-0.34-0.146-0.637-0.346-0.891-0.6s-0.454-0.551-0.601-0.891s-0.22-0.702-0.22-1.086c0-0.383,0.073-0.744,0.22-1.084
			s0.347-0.637,0.601-0.891s0.551-0.454,0.891-0.602c0.34-0.146,0.702-0.219,1.085-0.219c0.321,0,0.628,0.053,0.922,0.16
			c0.293,0.107,0.56,0.254,0.798,0.439c0.238,0.188,0.442,0.408,0.613,0.663c0.171,0.256,0.294,0.534,0.37,0.834
			c0.011,0.038,0.016,0.077,0.016,0.119C108.759,87.245,108.761,87.285,108.764,87.322z M104.657,87.1h3.045
			c-0.068-0.162-0.158-0.311-0.269-0.447c-0.111-0.137-0.238-0.254-0.381-0.352c-0.144-0.1-0.302-0.176-0.474-0.23
			c-0.173-0.056-0.353-0.084-0.539-0.084c-0.203,0-0.397,0.033-0.582,0.097c-0.186,0.063-0.353,0.152-0.503,0.267
			c-0.15,0.113-0.279,0.25-0.388,0.406c-0.109,0.157-0.191,0.329-0.246,0.516c0.027-0.038,0.068-0.076,0.124-0.114
			C104.5,87.119,104.571,87.1,104.657,87.1z"/>
		<path fill="#386539" d="M109.821,83.698c0-0.097,0.018-0.187,0.054-0.272c0.036-0.084,0.087-0.158,0.15-0.223
			c0.063-0.063,0.138-0.113,0.223-0.149s0.175-0.055,0.271-0.055s0.188,0.019,0.272,0.055c0.084,0.036,0.158,0.086,0.223,0.149
			c0.063,0.064,0.114,0.139,0.15,0.223c0.036,0.086,0.054,0.176,0.054,0.272s-0.018,0.188-0.054,0.272
			c-0.036,0.084-0.087,0.158-0.15,0.223c-0.064,0.063-0.139,0.113-0.223,0.149c-0.085,0.036-0.176,0.055-0.272,0.055
			s-0.187-0.019-0.271-0.055s-0.159-0.086-0.223-0.149c-0.063-0.064-0.114-0.139-0.15-0.223
			C109.839,83.886,109.821,83.795,109.821,83.698z M111.027,85.065v5.437h-1.025v-5.437H111.027z"/>
		<path fill="#386539" d="M113.522,85.065c0.031,0.045,0.057,0.086,0.076,0.124c0.018,0.038,0.037,0.084,0.055,0.137
			c0.016,0.055,0.025,0.111,0.025,0.174v0.223c0.062-0.121,0.148-0.227,0.258-0.318c0.111-0.092,0.236-0.168,0.373-0.23
			c0.139-0.062,0.287-0.108,0.449-0.14c0.16-0.031,0.322-0.046,0.488-0.046c0.305,0,0.582,0.054,0.834,0.162
			c0.252,0.109,0.467,0.268,0.645,0.475s0.316,0.461,0.414,0.761c0.1,0.3,0.148,0.642,0.148,1.025v3.091h-1.025v-3.091
			c0-0.452-0.084-0.808-0.248-1.064c-0.166-0.257-0.422-0.386-0.768-0.386c-0.207,0-0.402,0.023-0.588,0.07
			c-0.184,0.047-0.348,0.109-0.492,0.189c-0.143,0.078-0.258,0.174-0.344,0.284s-0.135,0.228-0.145,0.353v3.645h-1.025v-5.437
			H113.522z"/>
		<path fill="#386539" d="M120.001,89.131c0,0.055,0.008,0.109,0.025,0.165s0.041,0.106,0.072,0.153
			c0.031,0.046,0.07,0.084,0.117,0.113s0.102,0.044,0.162,0.044l0.125-0.015c0.148-0.042,0.277-0.062,0.389-0.062
			c0.141,0,0.266,0.035,0.373,0.108c0.107,0.072,0.197,0.162,0.27,0.269l0.123,0.182c-0.062,0.062-0.145,0.123-0.25,0.184
			s-0.219,0.113-0.338,0.158s-0.238,0.082-0.361,0.109s-0.232,0.041-0.33,0.041c-0.217,0-0.412-0.036-0.584-0.109
			c-0.174-0.072-0.32-0.172-0.441-0.3c-0.119-0.128-0.213-0.28-0.279-0.458c-0.064-0.178-0.098-0.372-0.098-0.582v-3.087h-0.906
			v-0.56c0-0.286,0.264-0.43,0.793-0.43h0.113v-1.118c0-0.11,0.014-0.214,0.039-0.312c0.025-0.096,0.055-0.179,0.086-0.248
			c0.037-0.082,0.078-0.156,0.123-0.223h0.777v1.906h1.506v0.983h-1.506V89.131z"/>
		<path fill="#386539" d="M126.251,90.502c-0.031-0.045-0.057-0.086-0.074-0.124c-0.02-0.038-0.037-0.083-0.055-0.138
			c-0.018-0.053-0.025-0.111-0.025-0.173v-0.223c-0.062,0.124-0.148,0.232-0.26,0.323c-0.111,0.092-0.234,0.168-0.373,0.229
			s-0.287,0.105-0.447,0.137s-0.324,0.047-0.49,0.047c-0.307,0-0.586-0.057-0.836-0.171s-0.465-0.276-0.643-0.487
			c-0.178-0.21-0.314-0.465-0.414-0.764c-0.098-0.298-0.146-0.632-0.146-1.002v-3.091h1.025v3.091c0,0.453,0.082,0.808,0.248,1.064
			c0.166,0.258,0.422,0.386,0.766,0.386c0.207,0,0.404-0.024,0.588-0.075c0.186-0.05,0.35-0.118,0.492-0.204
			c0.143-0.087,0.258-0.187,0.344-0.3c0.088-0.115,0.135-0.234,0.146-0.357v-3.604h1.025v5.437H126.251z"/>
		<path fill="#386539" d="M129.509,85.065c0.031,0.045,0.055,0.086,0.074,0.124s0.037,0.084,0.055,0.137
			c0.018,0.055,0.025,0.111,0.025,0.174v0.379c0.062-0.125,0.148-0.24,0.26-0.348c0.109-0.107,0.234-0.201,0.373-0.282
			c0.137-0.081,0.287-0.146,0.447-0.191c0.16-0.047,0.324-0.069,0.49-0.069c0.279,0,0.543,0.049,0.791,0.149l-0.078,0.44
			c-0.027,0.145-0.088,0.258-0.186,0.339c-0.096,0.081-0.215,0.122-0.357,0.122c-0.018,0-0.027-0.001-0.031-0.003
			c-0.004-0.001-0.01-0.003-0.018-0.003c-0.01,0-0.021,0-0.039-0.002s-0.049-0.003-0.094-0.003c-0.207,0-0.402,0.03-0.588,0.091
			c-0.184,0.061-0.346,0.139-0.486,0.235c-0.139,0.097-0.252,0.206-0.338,0.329c-0.088,0.123-0.135,0.246-0.146,0.37v3.448h-1.025
			v-5.437H129.509z"/>
		<path fill="#386539" d="M137.71,87.322c0,0.121-0.023,0.229-0.068,0.324c-0.047,0.096-0.105,0.176-0.174,0.244
			c-0.07,0.066-0.146,0.119-0.23,0.154c-0.086,0.037-0.166,0.055-0.24,0.055h-3.781c0.031,0.211,0.1,0.406,0.207,0.586
			c0.107,0.179,0.24,0.335,0.398,0.468c0.16,0.134,0.338,0.237,0.539,0.313s0.408,0.113,0.627,0.113c0.158,0,0.311-0.02,0.457-0.059
			c0.148-0.04,0.287-0.096,0.418-0.168c0.068-0.039,0.137-0.066,0.201-0.084c0.066-0.017,0.139-0.025,0.219-0.025
			c0.109,0,0.209,0.021,0.299,0.062c0.09,0.041,0.176,0.102,0.254,0.181l0.197,0.196c-0.258,0.277-0.562,0.495-0.912,0.656
			c-0.348,0.16-0.727,0.24-1.133,0.24c-0.383,0-0.746-0.073-1.086-0.221c-0.34-0.146-0.637-0.346-0.891-0.6s-0.453-0.551-0.6-0.891
			s-0.221-0.702-0.221-1.086c0-0.383,0.074-0.744,0.221-1.084s0.346-0.637,0.6-0.891s0.551-0.454,0.891-0.602
			c0.34-0.146,0.703-0.219,1.086-0.219c0.32,0,0.627,0.053,0.922,0.16c0.293,0.107,0.559,0.254,0.797,0.439
			c0.238,0.188,0.443,0.408,0.613,0.663c0.172,0.256,0.295,0.534,0.371,0.834c0.01,0.038,0.016,0.077,0.016,0.119
			C137.706,87.245,137.708,87.285,137.71,87.322z M133.604,87.1h3.045c-0.068-0.162-0.158-0.311-0.27-0.447
			s-0.238-0.254-0.381-0.352c-0.143-0.1-0.301-0.176-0.473-0.23c-0.174-0.056-0.354-0.084-0.539-0.084
			c-0.203,0-0.398,0.033-0.582,0.097c-0.186,0.063-0.354,0.152-0.504,0.267c-0.15,0.113-0.279,0.25-0.387,0.406
			c-0.109,0.157-0.191,0.329-0.246,0.516c0.027-0.038,0.068-0.076,0.123-0.114S133.519,87.1,133.604,87.1z"/>
	</g>
</g>
<g>
	<path fill="#386539" d="M28.947,101.893l1.385-4.928h1.85c0.227,0,0.438,0.029,0.631,0.087c0.193,0.059,0.365,0.142,0.519,0.249
		c0.151,0.108,0.282,0.237,0.39,0.388s0.189,0.315,0.247,0.498c0.057,0.183,0.084,0.379,0.084,0.589
		c-0.001,0.209-0.032,0.428-0.097,0.655c-0.063,0.227-0.154,0.444-0.271,0.655c-0.117,0.209-0.256,0.405-0.414,0.588
		c-0.16,0.182-0.335,0.349-0.527,0.499c-0.191,0.149-0.395,0.278-0.606,0.384c-0.213,0.107-0.433,0.19-0.657,0.249
		c-0.227,0.058-0.453,0.087-0.68,0.087H28.947z M30.989,101.213c0.245,0,0.489-0.048,0.731-0.14
		c0.243-0.093,0.469-0.222,0.675-0.383c0.208-0.161,0.389-0.351,0.543-0.567c0.155-0.217,0.268-0.447,0.337-0.692
		c0.068-0.245,0.086-0.477,0.053-0.692c-0.033-0.218-0.107-0.406-0.225-0.568c-0.115-0.162-0.27-0.289-0.46-0.384
		c-0.19-0.094-0.407-0.142-0.652-0.142h-1.17l-1.002,3.568H30.989z"/>
	<path fill="#386539" d="M38.08,99.781c-0.021,0.08-0.058,0.151-0.106,0.215c-0.048,0.063-0.103,0.117-0.161,0.161
		c-0.059,0.046-0.119,0.08-0.181,0.104c-0.063,0.024-0.12,0.036-0.171,0.036H34.95c-0.019,0.14-0.009,0.27,0.028,0.389
		c0.038,0.119,0.098,0.223,0.178,0.312c0.081,0.089,0.18,0.157,0.299,0.208s0.252,0.076,0.395,0.076c0.105,0,0.211-0.014,0.316-0.04
		c0.104-0.026,0.207-0.063,0.309-0.112c0.053-0.025,0.102-0.043,0.149-0.055c0.047-0.012,0.097-0.018,0.149-0.018
		c0.073,0,0.136,0.015,0.188,0.042s0.098,0.067,0.135,0.12l0.095,0.131c-0.224,0.184-0.467,0.328-0.728,0.436
		c-0.262,0.105-0.529,0.16-0.799,0.16c-0.254,0-0.48-0.049-0.679-0.147c-0.2-0.097-0.359-0.229-0.48-0.398s-0.198-0.366-0.233-0.591
		c-0.034-0.227-0.014-0.467,0.058-0.722c0.071-0.254,0.187-0.495,0.348-0.72c0.161-0.227,0.349-0.424,0.565-0.592
		c0.215-0.168,0.45-0.302,0.703-0.398c0.254-0.098,0.508-0.146,0.762-0.146c0.214,0,0.408,0.035,0.582,0.105
		c0.176,0.072,0.324,0.17,0.448,0.293c0.123,0.124,0.218,0.271,0.283,0.44c0.065,0.169,0.097,0.354,0.091,0.554
		c0,0.025-0.004,0.051-0.013,0.079C38.092,99.729,38.086,99.756,38.08,99.781z M35.395,99.634h2.022
		c-0.016-0.108-0.048-0.207-0.095-0.298c-0.048-0.09-0.111-0.168-0.188-0.234c-0.076-0.064-0.168-0.115-0.271-0.152
		c-0.105-0.037-0.219-0.056-0.343-0.056c-0.135,0-0.271,0.022-0.405,0.063c-0.134,0.043-0.262,0.102-0.383,0.178
		s-0.232,0.165-0.334,0.27s-0.188,0.219-0.26,0.342c0.026-0.025,0.061-0.049,0.104-0.074S35.337,99.634,35.395,99.634z"/>
	<path fill="#386539" d="M39.833,98.281c0.012,0.031,0.021,0.058,0.026,0.083s0.009,0.056,0.011,0.091s-0.003,0.074-0.015,0.115
		l-0.04,0.141c0.024-0.062,0.062-0.116,0.11-0.164c0.052-0.049,0.109-0.09,0.174-0.126c0.065-0.036,0.135-0.065,0.209-0.089
		c0.074-0.025,0.147-0.044,0.22-0.059c0.071-0.016,0.14-0.026,0.204-0.033c0.062-0.006,0.117-0.01,0.161-0.01
		c0.254,0,0.478,0.049,0.669,0.146c0.191,0.097,0.343,0.23,0.455,0.398c0.111,0.168,0.182,0.365,0.209,0.592
		c0.027,0.225,0.005,0.466-0.066,0.72c-0.072,0.255-0.185,0.495-0.338,0.722c-0.154,0.225-0.335,0.422-0.542,0.591
		c-0.206,0.169-0.433,0.302-0.679,0.398c-0.246,0.099-0.498,0.147-0.752,0.147c-0.08,0-0.159-0.006-0.238-0.018
		c-0.077-0.012-0.153-0.031-0.225-0.059c-0.073-0.027-0.142-0.064-0.207-0.111c-0.064-0.047-0.121-0.106-0.17-0.178l-0.415,1.479
		h-0.682l1.342-4.777H39.833z M40.037,101.281c0.164,0,0.324-0.032,0.479-0.096c0.155-0.062,0.298-0.148,0.428-0.256
		s0.243-0.233,0.339-0.379c0.096-0.143,0.166-0.299,0.213-0.464c0.046-0.165,0.062-0.319,0.048-0.464s-0.057-0.271-0.126-0.379
		c-0.069-0.107-0.164-0.192-0.285-0.256c-0.119-0.062-0.261-0.095-0.426-0.095c-0.177,0-0.358,0.035-0.545,0.105
		c-0.186,0.069-0.354,0.165-0.508,0.286l-0.45,1.604c0.085,0.121,0.201,0.217,0.348,0.287
		C39.697,101.246,39.859,101.281,40.037,101.281z"/>
	<path fill="#386539" d="M44.797,101.893c-0.013-0.029-0.021-0.057-0.027-0.082s-0.009-0.056-0.01-0.092
		c-0.002-0.035,0.002-0.073,0.014-0.114l0.042-0.149c-0.064,0.084-0.142,0.154-0.232,0.216c-0.09,0.062-0.187,0.111-0.289,0.151
		c-0.104,0.041-0.212,0.07-0.323,0.092c-0.113,0.021-0.225,0.031-0.334,0.031c-0.205,0-0.379-0.038-0.523-0.114
		c-0.146-0.075-0.257-0.184-0.336-0.323s-0.123-0.309-0.133-0.507s0.021-0.421,0.089-0.666l0.577-2.054h0.681l-0.577,2.054
		c-0.084,0.3-0.096,0.536-0.033,0.707s0.209,0.256,0.438,0.256c0.137,0,0.271-0.017,0.404-0.05c0.132-0.033,0.253-0.078,0.365-0.135
		c0.11-0.059,0.205-0.125,0.284-0.201c0.079-0.075,0.133-0.154,0.163-0.236l0.672-2.395h0.682l-1.014,3.611H44.797z"/>
	<path fill="#386539" d="M48.146,98.281l-1.015,3.611h-0.68l1.014-3.611H48.146z M47.599,97.373c0.018-0.063,0.048-0.123,0.087-0.18
		c0.04-0.057,0.087-0.105,0.141-0.148c0.055-0.042,0.113-0.076,0.177-0.1c0.062-0.023,0.126-0.036,0.19-0.036
		s0.121,0.013,0.171,0.036c0.049,0.023,0.089,0.058,0.119,0.1c0.031,0.043,0.051,0.092,0.059,0.148
		c0.009,0.057,0.003,0.116-0.015,0.18c-0.018,0.064-0.047,0.125-0.087,0.182c-0.039,0.056-0.086,0.104-0.142,0.148
		c-0.054,0.041-0.112,0.074-0.175,0.1c-0.063,0.023-0.126,0.035-0.19,0.035s-0.122-0.012-0.171-0.035
		c-0.05-0.025-0.09-0.059-0.121-0.1c-0.029-0.044-0.049-0.093-0.058-0.148C47.576,97.498,47.58,97.438,47.599,97.373z"/>
	<path fill="#386539" d="M48.326,101.119c0.051-0.022,0.103-0.043,0.151-0.057c0.048-0.015,0.097-0.022,0.142-0.022
		c0.061,0,0.113,0.011,0.161,0.034c0.047,0.023,0.102,0.061,0.162,0.113c0.042,0.037,0.093,0.063,0.149,0.079
		c0.059,0.017,0.119,0.024,0.186,0.024c0.072,0,0.146-0.012,0.222-0.033s0.147-0.053,0.214-0.096
		c0.066-0.042,0.127-0.095,0.178-0.156c0.053-0.062,0.09-0.134,0.113-0.217c0.018-0.064,0.023-0.119,0.017-0.166
		c-0.007-0.045-0.028-0.087-0.066-0.125c-0.036-0.038-0.091-0.074-0.163-0.109c-0.071-0.035-0.162-0.074-0.271-0.115
		c-0.114-0.043-0.225-0.094-0.331-0.15s-0.197-0.123-0.271-0.205c-0.073-0.079-0.124-0.178-0.151-0.292s-0.019-0.252,0.026-0.413
		c0.039-0.139,0.108-0.27,0.206-0.39s0.211-0.224,0.34-0.312c0.129-0.087,0.268-0.156,0.414-0.205
		c0.146-0.051,0.29-0.076,0.428-0.076c0.14,0,0.26,0.025,0.361,0.076c0.102,0.049,0.186,0.106,0.252,0.17
		c0.064,0.065,0.115,0.126,0.147,0.184c0.034,0.057,0.054,0.093,0.061,0.106l-0.199,0.138c-0.12,0.082-0.244,0.124-0.372,0.124
		c-0.046,0-0.092-0.011-0.139-0.029c-0.046-0.02-0.098-0.047-0.157-0.081c-0.04-0.023-0.085-0.035-0.138-0.035
		c-0.09,0-0.168,0.014-0.232,0.038c-0.065,0.025-0.121,0.056-0.167,0.091c-0.046,0.036-0.081,0.072-0.105,0.11
		s-0.04,0.069-0.046,0.091c-0.019,0.067-0.017,0.123,0.005,0.168c0.021,0.044,0.056,0.084,0.101,0.117
		c0.047,0.032,0.101,0.062,0.163,0.087c0.063,0.025,0.127,0.05,0.191,0.072c0.125,0.046,0.243,0.099,0.355,0.157
		c0.11,0.061,0.204,0.135,0.279,0.222s0.126,0.192,0.151,0.313c0.025,0.123,0.014,0.27-0.034,0.439
		c-0.044,0.156-0.115,0.303-0.215,0.441s-0.22,0.262-0.359,0.368c-0.141,0.107-0.295,0.19-0.464,0.253
		c-0.168,0.062-0.344,0.094-0.525,0.094c-0.123,0-0.238-0.018-0.348-0.051s-0.205-0.082-0.291-0.146s-0.156-0.141-0.216-0.23
		c-0.059-0.089-0.101-0.19-0.126-0.305L48.326,101.119z"/>
	
		<path fill="#386539" stroke="#386539" stroke-width="0.2466" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M55.762,97.604h-0.007l-1.083,0.721l-0.166-0.316l1.48-0.982h0.351l-1.366,4.867h-0.414L55.762,97.604z"/>
	
		<path fill="#386539" stroke="#386539" stroke-width="0.2466" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M59.363,99.748h-0.015c-0.204,0.138-0.546,0.206-0.78,0.206c-0.866,0-1.308-0.659-1.072-1.499c0.247-0.879,1.1-1.512,2.021-1.512
		s1.419,0.633,1.172,1.512c-0.143,0.51-0.514,0.922-0.797,1.244l-1.938,2.193h-0.523L59.363,99.748z M60.235,98.455
		c0.189-0.674-0.147-1.141-0.822-1.141c-0.674,0-1.273,0.467-1.463,1.141c-0.178,0.633,0.188,1.143,0.821,1.143
		C59.406,99.598,60.058,99.088,60.235,98.455z"/>
	
		<path fill="#386539" stroke="#386539" stroke-width="0.2466" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M63.095,96.943c0.757,0,1.192,0.508,0.979,1.266c-0.127,0.453-0.567,0.94-1.078,1.071l0.002-0.007l-0.004,0.014
		c0.582,0.131,0.762,0.667,0.598,1.251c-0.245,0.874-1.051,1.438-1.91,1.438c-0.861,0-1.349-0.563-1.104-1.438
		c0.164-0.584,0.645-1.12,1.301-1.251l0.004-0.014l-0.002,0.007c-0.438-0.131-0.604-0.618-0.477-1.071
		C61.617,97.451,62.338,96.943,63.095,96.943z M61.785,101.604c0.641,0,1.188-0.481,1.353-1.066c0.18-0.64-0.169-1.065-0.754-1.065
		s-1.172,0.426-1.352,1.065C60.868,101.123,61.146,101.604,61.785,101.604z M62.488,99.102c0.523,0,0.994-0.405,1.132-0.893
		c0.15-0.537-0.127-0.895-0.63-0.895c-0.502,0-0.981,0.357-1.132,0.895C61.721,98.696,61.966,99.102,62.488,99.102z"/>
	
		<path fill="#386539" stroke="#386539" stroke-width="0.2466" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M66.671,96.943c0.757,0,1.192,0.508,0.979,1.266c-0.127,0.453-0.567,0.94-1.078,1.071l0.002-0.007l-0.004,0.014
		c0.582,0.131,0.762,0.667,0.598,1.251c-0.245,0.874-1.051,1.438-1.91,1.438c-0.861,0-1.349-0.563-1.104-1.438
		c0.164-0.584,0.645-1.12,1.301-1.251l0.004-0.014l-0.002,0.007c-0.438-0.131-0.604-0.618-0.477-1.071
		C65.193,97.451,65.914,96.943,66.671,96.943z M65.361,101.604c0.641,0,1.188-0.481,1.353-1.066c0.18-0.64-0.169-1.065-0.754-1.065
		s-1.172,0.426-1.352,1.065C64.444,101.123,64.723,101.604,65.361,101.604z M66.064,99.102c0.523,0,0.994-0.405,1.132-0.893
		c0.15-0.537-0.127-0.895-0.63-0.895c-0.502,0-0.981,0.357-1.132,0.895C65.297,98.696,65.542,99.102,66.064,99.102z"/>
</g>
<g>
	<g>
		<path fill="#386539" d="M31.693,68.375c0,1.4,0.41,2.59,1.229,3.57c0.819,0.98,1.827,1.47,3.023,1.47h1.382
			c0.79,0,1.462,0.325,2.019,0.977c0.557,0.651,0.834,1.438,0.834,2.362h-4.235c-1.998,0-3.708-0.812-5.132-2.436
			s-2.135-3.605-2.135-5.943v-12.6c0.826,0,1.534,0.347,2.126,1.04s0.888,1.522,0.888,2.488V68.375z"/>
		<path fill="#386539" d="M53.98,68.375v8.379h-7.286c-1.998,0-3.709-0.812-5.132-2.436c-1.424-1.624-2.135-3.605-2.135-5.943
			s0.711-4.326,2.135-5.964c1.423-1.638,3.134-2.457,5.132-2.457c1.998,0,3.711,0.819,5.141,2.457
			C53.265,64.049,53.98,66.038,53.98,68.375z M50.93,73.416v-5.04c0-1.4-0.411-2.597-1.232-3.591
			c-0.821-0.994-1.825-1.491-3.012-1.491c-1.187,0-2.191,0.497-3.012,1.491c-0.821,0.995-1.231,2.191-1.231,3.591
			s0.407,2.59,1.223,3.57c0.815,0.98,1.822,1.47,3.021,1.47H50.93z"/>
		<path fill="#386539" d="M62.629,76.754c-1.998,0-3.706-0.812-5.123-2.436c-1.418-1.624-2.126-3.605-2.126-5.943
			s0.708-4.326,2.126-5.964c1.417-1.638,3.125-2.457,5.123-2.457h4.235c0,0.924-0.278,1.711-0.834,2.362
			c-0.556,0.651-1.229,0.977-2.019,0.977h-1.382c-1.196,0-2.207,0.497-3.033,1.491c-0.825,0.995-1.238,2.191-1.238,3.591
			s0.413,2.59,1.238,3.57c0.826,0.98,1.836,1.47,3.033,1.47h1.382c0.79,0,1.462,0.325,2.019,0.977
			c0.557,0.651,0.834,1.438,0.834,2.362H62.629z"/>
		<path fill="#386539" d="M74.706,59.955c1.998,0,3.708,0.819,5.132,2.457c1.423,1.638,2.135,3.626,2.135,5.964
			s-0.712,4.319-2.135,5.943c-1.424,1.624-3.135,2.436-5.132,2.436c-1.998,0-3.706-0.812-5.123-2.436
			c-1.418-1.624-2.126-3.605-2.126-5.943s0.708-4.326,2.126-5.964C71,60.773,72.708,59.955,74.706,59.955z M74.706,63.293
			c-1.184,0-2.187,0.497-3.006,1.491c-0.819,0.995-1.229,2.191-1.229,3.591s0.407,2.59,1.22,3.57c0.813,0.98,1.818,1.47,3.015,1.47
			s2.204-0.49,3.024-1.47c0.819-0.979,1.229-2.17,1.229-3.57s-0.41-2.597-1.229-3.591C76.91,63.791,75.902,63.293,74.706,63.293z"/>
		<path fill="#386539" d="M97.657,68.375v8.379c-0.825,0-1.534-0.347-2.126-1.04s-0.888-1.522-0.888-2.488v-4.851
			c0-1.4-0.41-2.597-1.229-3.591c-0.82-0.994-1.822-1.491-3.006-1.491c-1.196,0-2.205,0.497-3.024,1.491
			c-0.819,0.995-1.229,2.191-1.229,3.591v4.851c0,0.98-0.293,1.813-0.879,2.499c-0.586,0.686-1.298,1.029-2.135,1.029v-8.379
			c0-2.338,0.711-4.326,2.135-5.964c1.423-1.638,3.134-2.457,5.132-2.457c1.124,0,2.189,0.291,3.194,0.871
			c1.005,0.582,1.86,1.39,2.566,2.426c0.682-1.022,1.531-1.827,2.548-2.415s2.082-0.882,3.194-0.882c2.01,0,3.721,0.815,5.133,2.446
			c1.41,1.631,2.117,3.623,2.117,5.975v8.379c-0.826,0-1.535-0.347-2.127-1.04s-0.889-1.522-0.889-2.488v-4.851
			c0-1.4-0.409-2.597-1.229-3.591c-0.82-0.994-1.821-1.491-3.006-1.491c-1.196,0-2.205,0.497-3.023,1.491
			C98.066,65.779,97.657,66.976,97.657,68.375z"/>
		<path fill="#386539" d="M113.573,59.303v0.651h4.254c2.01,0,3.727,0.812,5.15,2.436s2.135,3.605,2.135,5.943
			s-0.715,4.326-2.145,5.964s-3.143,2.457-5.141,2.457s-3.709-0.819-5.133-2.457c-1.423-1.638-2.135-3.626-2.135-5.964V55.775
			c0.825,0,1.534,0.347,2.127,1.04C113.278,57.508,113.573,58.337,113.573,59.303z M122.062,68.333c0-1.4-0.406-2.59-1.223-3.57
			c-0.814-0.979-1.822-1.47-3.021-1.47h-4.244v5.04c0,1.4,0.412,2.597,1.232,3.591c0.822,0.994,1.826,1.491,3.012,1.491
			c1.188,0,2.191-0.497,3.012-1.491C121.651,70.931,122.062,69.733,122.062,68.333z"/>
		<path fill="#386539" d="M133.511,76.754c-1.998,0-3.705-0.812-5.123-2.436s-2.127-3.605-2.127-5.943s0.709-4.326,2.127-5.964
			c1.418-1.638,3.125-2.457,5.123-2.457h4.253c0,0.924-0.281,1.711-0.844,2.362s-1.238,0.977-2.027,0.977h-1.382
			c-1.173,0-2.171,0.5-2.997,1.501c-0.825,1.001-1.238,2.194-1.238,3.581c0,1.386,0.41,2.572,1.229,3.56
			c0.819,0.987,1.821,1.48,3.006,1.48h1.382c0.789,0,1.465,0.325,2.027,0.977c0.562,0.651,0.844,1.438,0.844,2.362H133.511z
			 M132.506,66.674h5.258c0,0.93-0.278,1.719-0.834,2.367c-0.557,0.648-1.235,0.972-2.037,0.972h-2.387
			c-0.395,0-0.729-0.158-1.005-0.475s-0.413-0.708-0.413-1.173c0-0.479,0.135-0.88,0.404-1.204
			C131.761,66.836,132.099,66.674,132.506,66.674z"/>
	</g>
</g>
<g>
	<g>
		<g>
			<defs>
				<rect id="SVGID_1_" x="64.823" y="28.346" width="119.136" height="73.697"/>
			</defs>
			<clipPath id="SVGID_2_">
				<use xlink:href="#SVGID_1_"  overflow="visible"/>
			</clipPath>
			<g clip-path="url(#SVGID_2_)">
				
					<image overflow="visible" opacity="0.4" width="86" height="200" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAADMCAYAAAD+icQbAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAADQJJREFUeNrsmYl22zgSRYmFq2wn
6Z7//8RJrIU7gHlVICnKSyQ7mW4xp+oEISVLJHHx8KoAJYnEPxrqd17MB//br/kvRdBK3w/wV2AD
/oUwX1ElYfMSDHMflFa/dSDUhyGf4arpqVQkTq/pkcL/Zfb8U8peHl6p+Ho6ko6494EH4dPg1c2w
CWgIDJmO+E/hfXpPR8aB//66B2EL4CPQNRitAysJPVWAi78DsvI0AIqOnwSvboHNoOfmvcZ7aEF7
7wDb6+AZvp4+w8+yUTeJU5bErAAcYDXAA6rHazr3GAhvcD6DR/sQdHUTbA8VJ14757R3zrjgjXNj
bN4ZgMfR60n1mu1m7ev3DlqtLITUjvMZsjHGaW280cZZY0eN13RO8On9j0JX12FDzWguODOOox3H
IXVusD2OdD46aqPFYNgwW0wyKT1swk4iBEAj0GQd3EjJ2owE3Np0SA1amg3WpH1q09FQ09rxwJDy
b4RurymbFOs8QANqP3RZ33c5jnnXdwUdqQ08CKPFwBgf3GQtpHK/HWuJwEP0aqjXGG+NGVKbEeg+
S/Muz4o2T/M2hKJLSUw2VYlJRh115T0BuwLdvpcgY9XhFVkGwwbotm+LtqurpkXDseXWlv3Q56T0
CD3oKbkuSfTOPX2ahuzJDF1rBduwIyxkINBFDtBZWVdlVbtiZ6ccpkL8Evsm7IXxrSudmxUeKxHA
dlA3bGQY+oxgN+1pd2qOD8f68HSqD491c3poumbXDwR9yDAwKR7GrJPsBkrEMD8hw044UcK7Aduy
spsyL+uy3B2Hsd9PM1mTIHX8jlc2jUkW/19T+SvgnOhwMR5FSobw5p6BNyXBPhyfvz4ff3w7nPZf
j83hCWp/7IauiiofUyTU2cvZVjYDPJmAx8qEEiTZSVdkRV1m5fGhb/ecv8AkzJ9l79Zuql40vZfE
PoebgF+UgFTqwZNHTpCwk7ap6ub4uD8B+OH738/H578O9f5b3dVPUP8OSZSAZ1TBxKqG1a2mQVT3
mzDVLIuYLAENyRAJkfw7JeCnXV4d+rEvkMt49pLHU6WCWTBOjSsZTqBXVP62pUzlHY0mEiL5NxRe
l5OdfNmf9t+eTz/+s6/3f5/a+kvTdzvYTjF6B0sJ9FBTaRi2VI/PSTNaitawlBTe3Z4we8vRjykJ
VyskU6paLFUrOVlOT68JPJl/EkG/q3L7xgTjZBnYUkZKmCnZRYfkCPvYnerjE6zk66E+/LVHO7XN
13YA8HFYAU94JbpS+F3XhNMqMyRzlUJJU2lAtV0xDiVsNaUZa5RxVB5SpQLlN2VRNcNYNpnLOiqf
46qbSjMVbvfwuHQne1G0mKGaG8ki66hC6duq6ZsdEuXjCa3u2idqHQF3Y47Pc9L0EMJFAXDP659l
DpKdJGFewkPlI/rUMWyyEfg6YFNpWJdFfah6KhSoJO5RnWHxF1fa512vW4CvlEgJj5fuI5ImqRwK
znCDAuBLKLok30YtvuvGvurGoRoZeLCs8I349zpZvrQVA5Vjjpu4rQLYve3aNK/boa2IQQ8BUvVG
VQuslxeHU1/Vz2a0fWkns/fOq0ZeZTq2FpSHQ0bgMaoY2TEnVY/cXDZ6nxHwZaW5bHRuwMcn4Oqc
RKnMdsSNyj6kRSzyhoISJ+yVj8whrrANFRe8p7TaOb3q4VOFQifL/naETptTnvZMLBIHjSaW8g7l
H73G0XtaCNiobioJI+xNbWCFsN4K53qaX9LaEYmM+omWsbAcrTeoPEQ9jtnPZeK8EOJd1JgSiOdb
lYp9USIt++6Ba/F4EfJznjKeFW/Yp32sRshCJhvhEcZSS8d987CtHUOlzkbAs5Q3nOOuKPrnWHSx
+aW5OVGqecs6XP4e8Erl+nXCfFmxnJfpET7fQCdxH1yvFjhzGbg92JfWt8zQKKAwQ9VRyVS9xYGI
uSryufU2+o2crdZLsGUhdAF+tozF+pZfgZItx3orIq64F9ElU15aKzqsFL0W6s8KBf3eXsp8t9WN
k6jkufAJyXnpHv6EH46T10k+zElw3oC76O/LPaNwQ/2rb98rDytLCuubJxvYEfzUBktYs3jxg8rl
+e37RfqzTzPfb7VB9UfGyjpn8L+0IacTiX80BLgAF+ASAlyASwhwAS4hwAW4AJcQ4AJcQoALcAkB
LsAFuIQAF+ASAlyASwhwAS7AJQS4AJcQ4AJcQoALcAEuIcAFuIQAF+ASAlyAC3AJAS7AJQS4AJcQ
4AJcgEsIcAEuIcAFuIQAF+ACXEKAC3AJAS7AJQS4ABfgEgJcgEsIcAEuIcAFuACXEOACXEKAC3AJ
AS7ABbiEABfgEgJcgEsIcAEuwCUEuACXEOACXEKAC3AJAS7ABbiEABfgEgJcgEsIcAEuwCUEuACX
EOACXEKAC3ABLiHABbiEABfgEgJcgAtwCQEuwCUEuACXEOACXIBLCHABLiHABbiEABfgAlxCgAtw
CQEuwCUEuAAX4BICXIBLCHABLiHABbgAlxDgAlxCgAtwCQEuwAW4hAAX4BICXIBLCPCtA1f4p6ZT
lYQ/GRC6Gfj/GCE59zf8buDh1a2XG6tw/rtaPdgfBfqMOYKGuM7vrM8/MgD67Zup8y3pwufTMP+V
j8toqz9U5SqqWy2CuugvoAeCsMz2RH0YeFiPlJouyi2ZGr9OwmqqhdWIbx+8mmbvWcFhEiD3eeHx
ol1e4n0B6pfKvpgpDHYNWgWtlMfRJ3xM/DwAyfQw08OGLcNWc1OxJUs/Y//1WoCL4G67hb2UdziP
Km6kl5vooLX2SuNmWju8dlorp+LNHTXc3NNXMII+JHGEtujbyVnFnvo0AY79XPrO/QcPDeCa+PhZ
bKtuh58Cx5cS59zi2Yq9CReki+EGRpvRajviOFhjBqPoNY5aj/gu3g8m8ZoGTaOpJbWHoDai7ClV
KVY0ATZaTX3T3E86WmMHYmGMIRZOab2of2IXZp5XFc4fDvMIax5lUrYx1uFGY5qmfWqpZV1qbZca
2+H9znlvp0nhfQgm8IycDH4DwFeeGyZbJeAsKOpfalL0N23ROm5pSu8NC3SIUi9efoZ+FTipGoBm
SwFs46HmkUaVQGdp3uZZ0RRp3hRZcerHoRy9T0nFpAiAT33wBp6il+e/91Sq1hVJrMSihWgC3mc2
bXKb1UWW1+h3naPvxCFNc4gt7a21BJ6hz5XMz9zUvlUS0hfItw2rOx2h6B6gW0Cuy6w8lXl52PVt
NbohwwBp8nM7DsXoXcoK5+XBhcrvGriaq614TsCcgVfTDM7StCmzYl/lJVp1KPPqWIBBnuUNWhct
xjo9W8uV0tC+8QCcCHiaaLaSIUuzLs+Lpiyq06562HdDV4xuTMk6aErBZNph6FfAg2bJbMG/VzXD
nPgoCZJ/Q8FdnmUNYB8eit33x/Lx+0P58LwrH45gUWdpAYvJ2Fq0MjQrgrpSodn3PC3eFDBtOmZZ
3hdZ1ezK7tgPfT6OQxrYRrTLbNaWfbuDveQYhMzBUoKPiXMLPj4lydm/l9KPiwNre7IQAr4rds9f
dl/++7h7+vEA0ZX5jlQOa8l6ym+Twv21VecFcK5UvItJUyvyCigcwG3eFUVZw0IsKhnDsGkwkDzK
tjxB8RUPBFQP4DYEz+oO4bwXcM/KXguNGtkJqRaJsc+gcLIQAN8/Vk/Pjw9P3x92T/uqJOBFy8mT
LIXXJZrN9L0K5W0P50o/sMIJKt0YltI7XzaoQfhKVApREiVPb4rm0A8Q+TBkBJySZoQd1pZy/8DV
NLNZ5ZS7ABwKR4KErRR1VVRHspJd9bh/qB7Jy1nh1qaDNlSXU+K8vvyw7+6lUNIkc0hQDiZneKwA
XJx8vYTq245gk7qH1LnRerITgr36Trhz4Oe9oWn5rtlSHCwFKqeCAVVZXqJVNXk3Nai+IQ+nKgWW
4mZ1X6vJXgGn6eCJM2c9zXATg+fIYkKlqRMTad5hlOt+6PJhnGFTSej0bCdkLVta1s9VBpeFKIk1
LfYIOtYdVJEQ4HjMW7QeljpYthO9Wvrrjyt8gY6DJhfBCjOuxuiChuxkoDqUkicpG1ZC3j57t57U
nWypSlHRKuMqMeFtDACEymlxQ4s+6jMqEjqiVKaEOtqpOomrca7dby/73wpAZ2ix6vAaULVH0qRK
BIqOzTvj0Rz5Oz6L70wl4Z3X368Eft7x5GpFx5W2iUqnXIalPkOGotGs8YaVfTvsm5LZAn1uAEtQ
UW9rgIZ9eI0BYdDTZ+7ds6/+whO3RKAxzSVi3JzStHwn+ImfVD1XczfDvrl6IOhJiAsZ3mwhq4hq
jhYy7Qa8ZSEh2YStvLVCJJUn521aPdXp0+JGJTd59qeAL9CnbZnJKtS0nRu3EMK0GRHCh699pzV5
svohIg5I3NyD18f3Pgr701AW+KvHXPxaJdv+WVld/jQwwV16+hnIvwz85oHYbvwyWAkJCQkJCYlf
jP8JMAB5Uiw2l6irHAAAAABJRU5ErkJggg==" transform="matrix(0.24 0 0 0.24 168.6387 59.0288)">
				</image>
				<g>
					<g>
						<rect x="170.297" y="77.627" fill="#FFFFFF" width="15.652" height="0.627"/>
						<rect x="170.297" y="86.354" fill="#FFFFFF" width="15.652" height="0.627"/>
						<rect x="170.297" y="69.016" fill="#FFFFFF" width="15.652" height="0.513"/>
						<path fill="#FFFFFF" d="M170.297,101.824c0,0.945,0.764,1.709,1.711,1.709h12.229c0.949,0,1.713-0.764,1.713-1.709v-6.742
							h-15.652V101.824z"/>
						<path fill="#693F8C" d="M185.949,62.398c0-0.944-0.764-1.711-1.713-1.711h-12.229c-0.947,0-1.711,0.767-1.711,1.711v6.617
							h15.652V62.398z"/>
						<rect x="170.297" y="69.528" fill="#CEB7D7" width="15.652" height="8.099"/>
						<rect x="170.297" y="78.254" fill="#DFCEE5" width="15.652" height="8.1"/>
						<rect x="170.297" y="86.98" fill="#F4ECF5" width="15.652" height="8.102"/>
					</g>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				
					<image overflow="visible" opacity="0.4" width="133" height="207" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIoAAADQCAYAAADYkvn1AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAGeNJREFUeNrsnQl34kgShFGpdHC4
e2Z3/v9P3Ok2h25pMzKrQKYBYZtDKor39GS7uz0gPjIis0I1s5l/+Id/+MetHsGrvvC2a/947V3b
ffhZoIKu/70KVOdBeSE4LBBdZ86zPiD26wMkAX0dBPI94HlFYIJXqRqA4yMY9D1+NjNH29Kff7we
QUB/oFQHUJRSLeAhSNpXBCZ4BTj6YLSzVgGKtpuptm0Uft629LNO/k5rqo1i2Qm6UIVtEKgGoIRh
2PSBeSVYgleBo23oYCDasCFA6AibpgnpXyucm1b+XKQHFYTlpo10VIdK12FoDhU2AgwdLwRL8Dpw
1GFVVxpf40xw0FHrupUzVZeQ/y79+0D8SacJEh3qKtJxGUdxSd/TOaFzXOkwrF8JlmCKcPTN6DVw
AIq6qSKcy7qM67qM6qaOqqqM6Yjo79GfEzAEyww+hbwJKgdVE4IkKpNknidRmqXJnA+CpYgAzAvB
oqdUNQiCD2bUVgDAIW9ypxgMAsTCQd8bKIqkJDBKOld8LpOKoMFRVlXccmVpwo5gC2biR1BB0iTN
5sliu0gXW/weglPZziiYJWx0O8jULHC6ouixwzFkRlvjNQAH3kjICOAADAQKgIiLIksBSFGVOKdF
WaQGmIQqDAFTxfTvIwalgzUJOkgOyU0BSObpcl0u397p9+t++yxGF50RPc0WT691dtaipwLHaTNa
s1xUFWTFVo+Kq0ZR5ImFoyizOcExz8sckMwBixxlCkjqto4BGf3+0HoUMq5VouNsni42b4t8gd8r
Zhdtc8CdEOSJvmlJfkBIp1punT0oYzKjdV1bMKJaKghBATjKRKqGQJEXGQFSECg5AJkXdFAVYUjK
BhWlTgg4+j0Ng2JmJK1WYZXEcbao8jX97pT+exrPC+2x5u5H15GWTqhRtm2W1+ViVdHPgIPBIO34
jhmFr6gb8hclVY2y6AMCKFILBx0LwFHUJUGSG0iqtKyrhN78hLoe/F4ytI2m56LMJLZl6SmirCjL
Of7beO5hqOBbqojNbFokcVrAx3DbTE+XKGpd9Sr6kVXjVmbU+gsAQhCk+DoneSlZZnKWmT4cJD/8
ZwRHWjU1A1I1AK0hb9IyJC39t8WoirREoS7pmAMkTN7CgLsgtMc5GdxdGiVZTkYXPoaeadVKBxSY
jsxXlPGY0dycAUCR5oV4D4FD5AWA0O9KUEHodwMOOgCHqSJdq5uu0yI5nbIjfvgPAqqOdRTNxLOw
FBEUebqbb6ULWm4WVbGl/z63y/TrqOC0DTogF+VH30VWHmBG2XNARqzfEDDw9RxVA3AADAIkNnDE
7EMAR9tqnKmK0HPpQq5uBK59LQq1rlO1rTDkP+q4jIpdsdsuit1bVuze6VgSwOsqWaBjYu/Umtba
S88FOCArjzSjB1NKFQOSUqNa1Gh1E0gK4Gi7hk1q06GCHODgakZnOxPhRR2pJiwb1NJ0FhzUlqIK
yeeUWVbky6zIVlmeLfMiX+T8nPB8qyiOakxeVKg6J+VHfxWQY0PaYa3kCWbUwMEVw8pKTb6DINEf
4RBAAEd3kJk+JP1xNf24nTX0ghg6VcMLzfOqWORVvshKgJLx88TArmlkBkM1RfF1UahQbsmP/nIF
6dr9yiu8Br0p4Sk4HmFGuXPhQ2SFZeAMHPTOqcP4f7+E8XEpo8NHgIpJ22mqENpAiMolzwvPkc9m
cFfLMoCVHzULm5eVnn0V4QssVUNMqPEa7DdqjTKMT1kfjvub0WZfNQCFPdtqcQKOYzAO3weIpZhK
Q3DBwwA+eB0AytDiORqgURFlKaDScdQojVyLg/KjPwMJqggkRjoUWXXFp4k8Bncnsm5SigntwXFv
M9qrGBYO1ZeUP+A4kpo/oMFc3kAlALaokBGqGCTPVhXALhPeChChqoXibeT3uyQ/+jOQ7FtZqhZ0
gYyMsGzwBbO6TUZvvofjzmb0lN84KSmX4DjFi4HLVihULzwvVBULOCoi5BNeCxJr4gp0nULl2vBN
fwYSVJHKAEIwEBC7BXUBizzf8hktI7oBOi9wfqQZvQUcf1SVvvx08CptZKpdYishPFbJsFRSUdom
tPHKl/EopyHJU2oPF7t8s9zu1m/bfLfCeZdtMV9Y7uh7khgCJVs+yIye9xvffNj/BlcV8kF4noAa
r8N6K2vKpcUnWCKChTo/2ya7sqKsB6oJLlcAuZGWtkgAyTZbv71vfv3c7NY/3zfvf9H3Pza7zY+M
h1HZKqvyJQNSwvhV6V3NaHe/T+5efmZGfgTomA02VRQYcNOtcRfXcFWp2eBrUiqYWlfkRw9XE1mH
gSeB39hmmxUged/8/vvX+7//Xe9+//2+ff97m29/7giUHYFCgCzKhqqHMaQPNaN3gGXvU+h5V+yf
6n0HV9pOrsoTOwqg51e4NqXV11QTzpZSyQUou2y9QiX5vfn1n1/rf//5tfn3n022+Xudbf7KyvyN
LuBCjGqdWjieYUZvoDuH7gdJpg5eBZCz/MDEi+8SH8ZVBcsPZq1KI8/ikvzoS/rctnZegvlIGUNO
yIMs19v1jzVVkfft7//+3v3+5323+c822/3Mq3xVVNUCn7h+BXm4Gb1TRYF3gtGu91WlkOFgadvk
gqsK5Ien02ZK64L8nAfF3hgl01bMS3hwBsOa5du3NVWVdb75a73b/L3Jtn+TN/mRVeWKpCatubXt
jMQ8z4zeAxZ0Y5Af6dykzTftf4qFTPppjKm0a4uEA11PG2CllxfxGl7EizEjEVh2q12BTieXgyAh
2VlSZ5BIie70VXB0I24jj+SHfQokVPPwjae0RX9KW3GbHNfG1PYXCacuP/qckZXrNJPVYPo0kUfh
nIiZwiZygbirwSQVrS9CPmRc2xhyc9S1TAeOS1LM3ooHbxFPjHlKK7MiHjbKZJmHb/hw6TB2Rn7U
hUsjY+h2f5tlYO+uMwEjnn9wpaGLZmcgPU+iWoFFMSSAwx5Tg0S8lDpMaVFRRH64quzlh2AheZbr
c5AfF4Zv6spLJZPGbsYLgodoQa+t5SHZTP7sIDeTheOo+tkIp7LyI2YdU9oyPZafqrKT2pqHbzaj
4ygoUirtzdpcOnEPC1VfdIsXSumkJebilPZIfuBTMFDkpYnKLHoa+UFFgfzsu59OMipOgWI3kLHL
HbgVge/mNzdom7v6+UCeVO5z2YPjZAr9WH4Aix2+2e6nd88Qfhajylr5cVp6eG+QAHfEARTy8JI0
xz0tFW5noO8rgqdWBApW5KkX7APjECXH8jPbD994Ndm0yv27ELHkIXcUNBwm7yYuP2dBCYzc2H1B
QnPTUxxFJW5b0EqXPH20sFB1EYma2d2Juplj0Bzk53JGpZIc7dmMinsVxQa9SJY1wRBFsWwBoeOC
73HRusBtDFRxqjAICKRgD4ur8jP7ckalVV3nYkWBYcX9tCrcVxQCpQQodC5wj0scxoUGLGFYhoGC
BDUBy9DMPVj6Sw4vmFE5Ccphgog9P2bWxNb2Dn/c8IQ75hgWHRVRiNsqw0odYOmc9Co9+flURqVp
PrTJU5Sf8xXF7PkBQ4vtHbBFFXYaQlVJYkBChyZQoihHVQlFgmo2tLOg3Vdr13zKNzIq/Q0HnRu4
KZ6bUBtMR8xbVEk1IViyJEoywBKFmqpKWPYqCj4yrcte5QsZFTXlKe1ZUCA/B5+iZNM7kh/sZZbg
Htw4RVXJsGUVyQ/BEsGrGPmRNtk5+bE+pReR/ExGZcryMzBHkeuhQgzbdKND2fIBVYUrSpxm2GwG
XoX8C+SHvIqpKo7Lz7cyKhOUn0Hp4VmKkR9raHnzu3iepUZ+qMJk8CoRdz+oKq8lP4MZlepjRmWK
8jMMypH8YKfEvfwkc1SVHeSHfk7So8tj+XGw7fkgP/uMSnddRmWq8nMRFPYpJ+SHjvJYflBV4FMU
VZSD/Lg7pbWt8mczKlOVn6sWq47lh1vkhLemytNe92Plh2Dpdz8vsUjoekblOlB68oOqIvJj2+R0
3yqjTcaUVitV8f7xrsLyghmV4TnKkfxIm6xrkZ+4YOk5dD/sVezwzULi/iKh+xmVq3MSVn6wr6rs
Dx9VZGIPo3xUl+gwfJMp7WvJj8sZletBMfLD6z4kPyHPVHhxsEjRKidmSisVpURV6c1TOvflx+2M
ylWg/NH9hGETRdT9oKrE2HOVgNH7RcJc8ioEigprm35zfZHQ9YzKp8rfIcwU8vbeaJVjAiXZryRz
51NEPKU9ZFQ4LikVxd1FQsczKp8DJbD5WWRUNA/fdMiryQWvKGuBxWdU3MuoXA3KqYwKAVFxNJK9
Ctrk1MxUfEbFtYzK5yrKmYyKmdL6jIrDGRX1tX/kMyonW2WHMyqfAsVnVC74FMczKp+uKD6jMlxR
XMyofEl6fEZlGBbXMipfA8VnVC7Kj4sZlU+D4jMqw62yixmVLy9M+YzKGelxNKPydVB8RuWk/Agw
7mVUvjZH8RmVwSmtaxmVb2UifEZlWH5cyah8DxSfURmQH3cyKl8GxWdUrpEfdzIq3y51PqNyWn5m
jmVUvg+Kz6icHr45llH5Fig+ozIsP65kVL5fUXxG5bL8OJJRUbf7RT6jcrJVdiSj8m1QfEblgk9x
KKNyk4riMyrDFWXqGZWbSY/PqAzDMuWMyu1A8RmVi/Iz9YzKTUDxGZXhVnnqGZWbLkL5jMoZ6XEg
o3JbUHxG5aT8CDDTzqjcbo7iMyqDU9opZ1Runn/wGZVh+ZliRuX2oPiMyoD8TDOjclNQfEblGvmZ
ZkblLmXNZ1ROy89swhmV+4DiMyqnh28TzqjcHBSfURmWnylmVO5TUXxG5bL8TDCjou77y31G5WSr
PMGMyl1A8RmVCz5lohmVu1UUn1EZrihTyqjcVXp8RmUYlqlkVO4Lis+oXJSfKWVU7gaKz6gMt8pT
yqjcfcHJZ1TOSM/EMir3B8VnVE7KjwAznYzKfecoPqMyOKWdSkblIVkHn1EZlp+xZ1QeA4rPqAzI
z/gzKncHxWdUrpGf8WdUHlbCfEbltPzMJpJReRwoPqNyevg2kYzKQ0DxGZVh+Rl7RuVxFcVnVC7L
z8gzKk9os3xG5WSrPPKMysNA8RmVCz5lAhmVh1YUn1EZrihjzag8XHp8RmUYljFmVB4Pis+oXJSf
sWZUHgqKz6gMt8pjzag85QZon1E5Iz0jzqg8BxSfUTkpPwLMODMqj5+j+IzK4JR2jBmVp+294TMq
w/IzpozK80DxGZUB+RlXRuUpoPiMyjXyM66MylO3ffIZldPyMxthRuW5oPiMyunh2wgzKk8DxWdU
huVnTBmV51YUn1G5LD8jyqiMZGtKn1E52SqPKKPyVFB8RuWCTxlZRuXpFcVnVIYryhgyKqOQHp9R
GYbl2RmVcYDiMyoX5WcMGZWng+IzKsOt8hgyKmosF8RnVM5Iz0gyKuMBxWdUTsqPAPP8jMo45ig+
ozI4pX12RkWN6aL4jMqw/DwrozIuUHxGZUB+npdRGQ0oPqNyjfw8L6OixnZRfEbltPzMnpxRGR8o
PqNyevj25IzKqEDxGZVh+XlWRmV8FcVnVC7Lz5MyKmqsF8ZnVM60yk/KqIwOFJ9RueBTnphRGWVF
8RmV4Yry6IzKaKXHZ1SGYXlkRmW8oFyZUXlV+Xl0RmWUoHwmo/Jq8mNb5UdnVNSYL8g1GZVXk59n
ZVTGDcqFjMorRyQFmMdmVMY7RzmRUbHyk9h5yotGJJ+RUVFjvyg+IjksP4/IqIwfFB+RHJCfx2RU
Rg2Kj0heIz+PyaioKVwUH5E8LT+zB2ZUpgGKj0ieHr49MKMyelB8RHJYfh6RUVFTuSg+InlBfh6Q
UZkOKD4ieb5VfkBGZRKg+IjkBZ/yoIzKdCqKj0gOVpR7ZlTU1C6Mj0hehuVeGZXJgOIjksPyc8+M
yqQqio9IXm6V75lRmZz0+IjkGem5c0ZleqD4bbxOyo8Ac7+MyqRA8dt4DU9p75VRUVO8KD6jMiw/
t86oTBMUn1EZkJ/bZ1SmN0fxGZUr5Of2GRU11YviMyqn5Wf2iYwKQaKvzahMFxSfUTk9fLsio8Ie
hecp5dUZlUmC4jMqw/JznFExC4WcURFYSjPOrzFPUUNtspryRfEZlQvyMztED/ZVBbCU8CcFQ2IH
bxxmMm3yOZ8ybVB8RuV8q0wVRbyKVBWuLE0J+WFgSHbiXuezl55zPmWyoPiMylCbPJPhG3mVtpUO
qK4BTB0j6WYqCkFS89ANYSbnPMq+oviMynGJ7cSm2DecW2XFMtS2oXQ5DTohyFEo6z2oPC2muYGd
pzgHyuFF+IzKsSQfG1wCQZkogm2FlUhNy6P7oRXkSYPiMyq9SmKqSbA/uLtrVWDPqj9s/PRDT/4a
oU1uTJvcfCKj0tqZShca+bEaPxWJ+VA0LAQ4o2LikC6Puj1ILn1AMJjEB4VkGB+oRiBSnf3AOQ2K
bZOvzqiUNqNSi/x0LD+BaSunAkcnr/sAx8x8vYeEANEst7qItc7t64/DuIg0DroSSlNlZcluZTal
OrdB+UpGpYb8kFrjDei6ScPRk5kGcyJ60yv4MZJbVNPtPJmv6QOzmceLDX1NR7pjD5ekAAaDyGbG
nk2aAydBwSeBzNjsWH6uyqi0h4wKHqOQnyM4rNm2cPQWNlsLiMDBgEBqasgrvc48jaJdmqSbVbr4
9WP+9u/bfPVrMV+9zxMGBrAUWPpgGaKukUEJHAXlnPwMZVSKGhkVKz9PLikn4LBvGHdmPUhsx2Yl
BssS7EUAifgQgiQsjNxuF1RN3harX2/zt//9WP6k48evt+WP90W63MYw+XSd6N+IuaeK/HFG5SIo
X8iolJxRaRt6J8KA28UH+ZQLZvQjHFZSZnsw+IOg9pWj0jIbgklFFSkjXtvSVE2SHWRmmSzWy3T5
+8fq578A5efbX/9bLt7e5/PllqpNjg8UexVbUZTDFeVYfj6TUanbQ0blrvLzab9xgIMlheOcZNRN
FyPRiRBglAy/hmmNCsgrPhBplMKb0LGgivL2m+D4TZUEwPxaLX5AflBRMJCEDDf4gF3qfJypKH35
+VJG5R7y8wUzegBE4ECHJrMfgYMrh5aFTpwxH8LCJ2TVyCt1eukujee7eTrfksRs5uliQ6CsV4vV
ejl/WwMS+tkOa2L0gapQgQPINipyoDr3QblBRuXb8nMDM4oz4BBTCjjgOwgG9lcarwf+wyxPxPvF
z33lREUhswrDas+LxYqkZsHdn3SCSYHlDviTYKCaOAXKH93PNRmVqjQZlXZvGL9UWG5oRq2syBRZ
CxxGUmzVEAnFFqoAX16TrG0ZLxaLL0sBTTLnSkofFPy9wvi2MuThm0DCU9sL1cS5imLl5ysZlYa9
SqeumtLe0YzCa8iQLBIAuHrY9p4h4KoR8xLFPMMHwGynKhIrZwzV2J+ZM/1eGF34EXg3ghNycyUk
boLynYxK15MfGcQFjzajPD3lytGHI90BjlQgyQUUBr9gQCAjIYBgGCwQiFzAf7Cx5wMfHp6ZYN1H
5OYaSJwDBS+4aZrgVEZFLuyZjErDU9qG3unQ3BvTzSwsF/zGncyonAUQhsN4CoZkDwiZdFQTzR+E
iI07Xit3fErWcrhq8FxJ8UBN8ZyEwWhtG3wNJG5WFGRUmo8ZFS6/8WFKG9sprRZzWKqKPnlB3XaB
xro7vdGz/rL7Ob9xDzNq4xGpnf2QvCTSsRUYIOKsZV5SiVlH1UClMBUD85BA2l14NR5CcgU5TF2v
rSJOg7KvLnZKqyA/hzATdwRoHyOsd+zMAE6XuK2hVbySPONNaQ4RjD8k5c5mdN/GCxxsQkuGI4oN
IJoXPqWz4/G7VAyZrsqE2oTPLSCfrSDOg2Lk5zClDfnTVqGqSIs85xkDvWE7TC9zOsqmTnFXXVez
mUW6BzHjoOdD9p7jnmY0OnQlqDplxNVD5kFa1mRqM3WuZ1zJCJBQqoWVFbuodws4nK8oxxkVmDpj
/HKeKaSLDR3rrMpXeVUscDM3ooJ8UYmyVrXaSo8J/TRWUiwg9zKjHOUkqCUuIXAY39H8KSkfq8b+
td8IjpeQng+LhCI/ZZosMqxxLPLVepnv3rMyX+J+XASPAQa9CQ22iuAsqRm84WfcGREg1m+I57if
GcVzvhaO/trMreFwHpSD/EB6VIM3g+cLZZJTJdnmi+U6L1YL3AzVNHWEHCmqRlQVBW6Uwn27AEeC
QFxBanoTP8DxWDP6HDheo6KY7kfaQgzedIVPNNY5lvMV36iNG6AQNEa7CNnIinwJUHDTVMfWJGg1
zCqZVG6n2ZQSDGGUP8uMPhKOlwClP3zTHLhOSqoyOfYvWxEgdY17WuBPgw4+g97aXZEUi5L9Cm6I
ItbI1vKoG56EIUkkrB0LHI8yo8+C4zXaY7P2Qw2sTGnJp+ANnKe13JQNSOjTC4hQJebpcoNtIeq6
wl3+ofEneDNrM+0sTOUwq9HTMqMelKuqCklPhFsmcT/LPEfqhN8MKvskKSVa5mWRrXHHP/Y2aw0o
GHMjsc7mNZK1k1gfFtamZEa/3xw4/sDNTbhTH5BQtdDwJfjfkuRlhn1C5thWE5vL8Ga9DW8uw3fP
2daY2mGCLKZ2WOM2EF5LsXIyJTPqQfkELKgU2AkRsOB/SYLNZHifEDqwpxnaYrtXyL7FloW0Wlps
Ts7x8rz1G1Mxox6UL1QWeJC64n1BwkqqDN+sbbd/sNtUWYMpC2zm6C222aoxBTPqQfkSLNgwBsDU
odzx33C14Xt028MOicpUBcgJXakOnmWqZtSD8gVY7L6qFpj93iC4m7/7eE2wkAw5sYEouxLrYtXw
oJyABWe7w5CF5vA37NcHCE5VDdfheHlQTkGzR+RoD7Pj+1xeBQz/8A//8A//8I8nP/4vwACa+pK0
0f8GSgAAAABJRU5ErkJggg==" transform="matrix(0.24 0 0 0.24 156.9453 60.9458)">
				</image>
				<g>
					<g>
						
							<rect x="162.964" y="80.66" transform="matrix(-0.9545 0.2982 -0.2982 -0.9545 357.9572 107.3259)" fill="#FFFFFF" width="15.652" height="0.627"/>
						
							<rect x="165.564" y="88.992" transform="matrix(-0.9544 0.2984 -0.2984 -0.9544 365.5339 122.8013)" fill="#FFFFFF" width="15.657" height="0.626"/>
						
							<rect x="167.947" y="64.872" transform="matrix(-0.2978 -0.9546 0.9546 -0.2978 148.9009 254.9213)" fill="#FFFFFF" width="0.511" height="15.654"/>
						<path fill="#FFFFFF" d="M170.443,106.105c0.281,0.9,1.242,1.404,2.145,1.121l11.672-3.648
							c0.904-0.283,1.406-1.242,1.125-2.143L183.373,95l-14.939,4.67L170.443,106.105z"/>
						<path fill="#DE991B" d="M173.623,63.805c-0.281-0.902-1.242-1.404-2.145-1.121l-11.674,3.646
							c-0.902,0.283-1.404,1.242-1.123,2.143l1.975,6.316l14.941-4.67L173.623,63.805z"/>
						
							<rect x="161.66" y="72.759" transform="matrix(-0.9545 0.2982 -0.2982 -0.9545 354.1671 99.5877)" fill="#E6AC19" width="15.655" height="8.1"/>
						
							<rect x="164.262" y="81.089" transform="matrix(-0.9545 0.2983 -0.2983 -0.9545 361.7414 115.0722)" fill="#ECBD29" width="15.656" height="8.1"/>
						
							<rect x="166.869" y="89.418" transform="matrix(-0.9545 0.2984 -0.2984 -0.9545 369.3209 130.5596)" fill="#F0C954" width="15.652" height="8.101"/>
					</g>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				
					<image overflow="visible" opacity="0.4" width="172" height="199" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALMAAADMCAYAAAA4YJLnAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAHRlJREFUeNrsnYta28gShCXN6Gob
SPa8/xOeswFs6z7S6eoZ2Q4JYEDGstTOpyV3NvZPuaaqNeN58pCHPOQhD3nIQx7ykIc83nr4S/hH
dn3327+173v6j/ux7/W+/9vT0Ad+IGQIzNMDeACXPvK/tadvL/7tvf0BffP9/gRwgVpgvj7EDK5T
Xny/7zvAHNCv0c/hx7/DDHChznR1vh/YHxP/AvVtPfQclRjgDhB3XRfw1Xf0c/b79tfA6RFiArbz
g6ALAkVXYH/ssVJ39GcFaIH5+9WYAbbQKtOZwBijjGl11xn8mK8BaAczQO2UUkYp3Wq66KNRgTIE
NV3KE6AF5u9T484qrVVio1pASxC3pgnb1l6NuwaoHcwegAW4WodtqMJah2Ed6ajWOmro5/jzMMSk
1gK0wPwdasw2gpW4bTUgbpomrNs6rusyrpoqrulq2jrCz0OpDzCTIodKN2EYVxFdSZQUJkrKOOoq
+j11r3VDam0CfN0I0ALzpUBmb9wd1bgFyAQsgRtVdZmUVZmWVZ4VVZFWTZni55qmjkm1dUd/NoBX
VqoNdVjHYVKmSZbXSbbPTLs3facS/hyxfaKUpk/mi+UQmC9jK7qelBjeuG00qW5YNwwyQVykRZln
eblf0bWm769wVXWRVQxzG+ILAX6Z/HFDtqJK43SfZatd3azx69rAV9PnYen3OfPwtPZa8dAC80Vs
RWtaqDF5YihxFZd1kZAKZ3mxW++L3SYv9ptdvuWPeZmvy7pckfVI2gPMXq8DXZO9KLM43a3q4glf
EC0vGDsmleSbPmPAUR19nxMOP1C+C/Z6QUhg/oIaI6mAIhtSY1Jk8sLWUhQplHhP8BLAd3Td7/Ld
3a7Y3u+L/V1R5ZuirjLATMobDTaD7ANZjLgo4nRbOdCRhHCNEjDA/RDdIbLDFwBSaK8T/ywwj6DG
WOQhmYAak3VICVRWYwfx3Xb//LDNnx8I5od9ubvflwC52FR1ndGfi8lfh/j7fALUwhwV5KmzxjQx
faFo/Jpna236TcrGdL7qfCizz0rdKmTQ9D0BWmD+ZORmbUVjvXGMhZ21FNs1qzBB/Lx/+rHNtw+k
yA/bAjDn90VVrglWupq0MW0M5e3BKrGolGriJiwAuWGQe+uQATFnzwq5M7wyLobaQz6tyG/TN7Eb
AvOnIje3yCNvDFthF3i7PdT4+Z7UGNcPgvnnjiDGta/y+7wqN2VdEcikygQy/V0h/Z0alTaUWQUB
fX20LuGAIrtGENkzQRyqqA512HCZEmgGPHBFCz6K3RCYPxy5WTUuAbJNKmiBx5bCgvywJUV+JphJ
jX/syt1DXhYW5KZak0+GIpNX7kJDIBN9HElw+xf4LeCGh2a+PfbIsBZoAxvEdppgVoqAtplzy80g
Qe0HQS92Q2D+cOTmFnlZgUUeWQtWYl7kPTPEuEiNf+zL/UNeFXdFDZDrVQ1r0UGRu4guQKt6NgcI
KGAVfLSBgcclijdU2y0XKQptYFgB6DAMWaGhzq41ZP/sK/ozvcR1AvOZkRt5Y9iKlVvkQZGdGpNH
LrY/6Mc/nD9+KNhWlOuyrVekxmnTGlrwdaFTXwa3dxNzPJUEIgGylWqkFkaVQaMDqHBYRWFUhe7S
UGcFxYaPht0I7JSdxHXLhvncyO1Qfri0YoCY7AUWeg7iPUC+I1txV0GN2wb+OGmMgRqHJzbCTswN
MGNC1P3/NKSrXtsm5INZeXWlyFYoUmMNm0HqHFmFZtsROQ9t1TkIvU7iuoXC/LnIDd7YKXLuvLFL
K2ArWI0BMqkx/V0J1NiC3KvOjoQOiny844T/0wfGmQRD/zv0RWCCpiYbETRIOhDdWbsRVRhAwsUL
woATDkPKDPg7iesWBvNXIzfyxYjcaIG357SCFnl3eW3TCqvGDdQY/phA7pFOKKgxYD6F2HN3nXhc
VlugO2Rx+B8zfkRrQkMKD+UdYIYqnyizWxRq1bjYrvN5DtonhRa7MXuYx4jctgC52L9Q4wa2InWx
WzSosfndH/u/QXwKtQMawNMvYjw0bI3X1S7RIA9OC0FdhwerYf1zFMYMtgpChllhoF/sxvxhvlTk
5rLj9IU3Vm6h578J8gugWZ2djyaLQgptusFu0IKvZoXWmOOISr40FoTkp1XYas3+GVGd2I25wvwd
kVtruthGbnaR19lFXvAuxC+Zdr+X/LOCTJMwR+Q92G5oZzcQ08U6Lh3QQ8pRY/LO3pkCDy3t4Oxg
/u7I7a+LvDNBPrUb1j+TTaEvkJb8d03+WTV1o8rC+md9VOaIB/pPGkIM8Us7OB+YrxW5dZ9Q49eA
7q3CE8z0j/DamGDMUHvrynrnyPlnVmfro7EgbGE5pB2cCcyTiNw+A/LvD9/BTH8RWaLeD/F5qxYw
28VgpDSnG9ZukDpHkU03Alt1Szt4wzBPInL7OsSDOve/xXX0ORuPFoN+k5bInqsSi8FKM8zxwTtL
OzgDmCcZuX0d6FO7YYE2xvj0haWCihTaNoNubuOPdlBJO3h7ME86chsD6FO70fm4nQrpRubiulfb
QdgNJe3gbcB8S5Hbl5g+tRsfbAftqCgm7GSYf7Iw31TkNpLd+Fg7yGBj4s7aDRnmnybML22F2+5q
2pHbCEB/oB10cV0M71zLMP9EYT4F2ebGHakx2wpOKshSAOTVhCO3r9qN19tBumA3IhVVMsw/cZgP
IHddgJ1+zImtKGsGOc3L3Xqf0yJv/8xqPKnIbSS78Wo7OPhnLcP8k4b5FOTOgcz7uLGtsGqM3JhA
BsT3z4ekYmKR27hx3Uk76GfHYX4tw/xThfk3a+EWegwyvLHbdCUvthuXGT887xjiH8/580/448lF
bl9/+C/jOhnmvxGYOXrzjh7ZFiGuySNbAUvxvH+0ENvrHwaZFTl/yKv8foqR2xfUuZdh/huE2aky
vuNh3zVkyNhdc9gGi0He/frxtH3852n3+POJQH7KyVrku587mx3fl9UEI7erxHUyzH9VmAdVNrae
VjwohPmKMk/JWqzJF98TyD9/bX/9h2D+z9Meqrz9SR75ByI3+OPKRm7p5CK3743rZJj/mjCfqjIv
/AxgrkPOkas82xe7Oyz0SI1JkR//edw//ud5v/0H8Rs8MtkKAhn+2CTtySLvJtX4s3GdDPNPRpl9
qxQ9H2iDLV5xlEJdVy694CwZ9TQv9hzIP7nRq+t1bbBBIUAeNl6ZYOR26bhOhvknZDPsN66s2WYY
HKtgd6Qvinydkzrvyt39rszvbfRG1oIUuW65CGGQ6QtBTzpyu1hcJ8P8U0szALJ3uFPE2Ek4HiDC
JFxdroqqWJV1sS6balXhcrU0KRT2Pw7NjGzF2XGdDPNPC2Z6Hq1zcyeb9sejyDQ2425w8E3DV4rI
rW6bpKa3WEBsE4te/wHy/CCWYf7bUebevV6I5np7OKTBGXqH8/S0hRsfsbOm9cZ2oefNH+TX7caH
h/mlHby8zbAbsfXeYW82+4J57ijfobHrGVbrr4ddgw7wzhvkl0B/cphf2sELwuzbM5Y4TuVzPeyZ
0sdzPbBo8fmtcbjswTWDOVno26O0g1NUZv/49NkNuQNcndLabsBNF4Zq8OIgM6UXoMHqnVfmPVJq
n+WanbctGJajzp60g1O0GXzKkj0qwZ7tgbdEHkCP4hJlAA62icOwiLSuqlZV9EYZ09trSGqufBxn
0zPQ/mKeTWkHpwmzzzYj8Ky1wPkeYYt8NIqSKonSIonTfRol+ySM8ziKctv4tcnQ9tFXAmD2F6XO
v9sNaQcnAnPvLAaaKbztmUGV4zAp+DTTJNvRtc2rYoucua7rtOGDbuygfcAnLPC29HahiIZrYXZD
2sEJwIwnrOs6z2ae9GTSE4v72fig9Dgt0iTbr9LVtqjWKxxLVjYljvFNa9PyGXtcX/d81DRMH2px
vLjLeRWkHZyYZ0aSQU8/Fn/K2QwoRxKTMier/aopt2VdZmUFkMuUT3Rq7d0j9ITb48lQaHUHdV6c
3fCkHZzOAtB5Z5tk4Ew8Uo04SkpS5rxuyl2Z0reKQG4qbgEBM71YEb2l8pG9PKiEAgVfE6jGoc7L
SjekHZwCzIPVwI0gIBFPKt7+4iiuWpPl9q7sodaukooufJ+AjvjgSAwZddZuwH1zyrHQdEPawesr
M/d3nGp4pMzoAchqGG3qOGyhznsM7GP3IoyGWpCbGOdPQ53pBQtbPgnVTc0h3PA6jQoRScdi7Ia0
g9OAmdXZVSC8ECRhCXsCGgtB02qT2Ek6DB4RxBFgrg2AtnbD2APXrd3w0Kf4mGleclwn7eDVlNm9
Dqi38Q3NVE9PKr0lBj15Z94okafoADJBDcvRWoU29PMndsMeKOlDoU/iugXaDWkHrwizU2ebsvVs
N7xeRy29KHVsd/wMm7R2MDdRbegjqzMAt4tBPq/axnXqt7hugXZD2sHrKvPBbrCHRrLhqZbsht91
SdDGRmWm0bgTBbdWuVnnBB9bTjcM2w1WacBs4zppB6UdvA7Mp3YjQPdBbCvVt2HY+TEB3WGuGZdd
EMa4i7s5LAhRpHD+TJajV7iCl3ZD2kFpB78T5lO7Qd8Q1RGLKFK6hmAu+KbXlJWZIzuyHPDPSQ2g
yVPDbrTdwW5IO+hJO3hNZf7DP9MT6kMpsEgxhoBOD0c/RNj5CPlzY2E+pBvSDh6DT2kHrwjzS//M
q2uyG5ogjKK4SvhAnub0HkFWZxQqNn820g4e1VnawWvD/CKus3aDFuYkuLUJE2XiJm/b9RDXkX+u
h2aQLiPt4Ot2Q9rBa8D8WlwXdV1tupT9M2C27WCdHBeD8M7SDv4BtLSDV1XmP+I6bgdDEpYuDkxs
d0CCf5Z28Oy4TtrBa8H8wm780Q7yNgQdFLqVdvBMuyHt4BVhfqsdTDpTYD/nNm2kHTwTaGkHr6vM
0g6ObzekHbwWzKd2Q9rBceyGtINXhFnawYvFddIOXkGZpR0c9yHt4DVhfumfpR38kjpLO3htmF/E
ddIOjmc3PtwOqgW2g6PDLO3gyEB/sh2E3VALawcvoczSDo4f132qHbSH0OPsbtV5C4jr9CVfhxHa
QfXXdnCBcd3H2kEGu4GHZrvB/nn+cd3FYP5MO9gOVgP+2baD6mg3lh3XfaAddHFdDO9cY1s1q9Dz
j+suqcwfbgcP/pk8tenIbtiqW+I674x2kC7YjUhFLoMmyxGGg382S4jr9He8Due0gwPErlCBd465
TJG47rx2cPDPNnseau5FxXUXh/mMdlDZdpCsBlmO6uCf2xjJhsR1r8Z1J+2gn0F5daX4RAPYDb3A
Yf7vUOb32sGyTds9+WWbcNQndkPiur89/JdxXWNaM/hnteBhfv1dn+iNdrBMu1aZttE2rmskrntb
nXsZ5r8yzC/iutN28DSuC2WY/1Jx3fyH+b8V5jPjulCG+UeP6xYxzP/dyizD/N8Z1y1smF9f63WQ
Yf5viOsWNsx/FZhlmP874rrlDfNfS5llmP+Scd1Ch/n1NT+5DPNfKK5b6DC/nsLLIcP8o9uNRW71
dXWYZZh/ZKAXvNXXFJRZhvnHj+sW2Q7qKb0OstXXeHZjie3gZGCWrb7GBXqJ7eCUlFnawfHtxqLa
QT3F10HawfHsxpLawcnBLO3gxeK62beDU1RmaQfHfSymHdRTfQWkHRxNnRfTDuqpvxzSDo5uN2bb
Dk4aZmkHRwZ65u3g1JVZ2sHx47rZtoP6Vl4HaQfHsxtzbQdvAmZpB8cFeq7t4K0os7SD49uN2bWD
+tZeB2kHx7Mbc2sHbwpmaQcvFtfNoh28NWWWdnDcx6zaQX2Lr4C0g6Op86zaQX3LL4e0g6PbjZtu
B28WZmkHRwZ6Bu3gLSuztIPjx3U33Q7qObwO0g6OZzduuR28eZilHRwX6FtuB+egzNIOjm83brId
1HN6HaQdHM9u3GI7OBuYpR28WFx3M+3gnJRZ2sFxHzfXDuq5vQLSDo6mzjfXDuq5vhzSDo5uNybf
Ds4SZmkHRwb6RtrBuSqztIPjx3WTbwf13F+HEdpB9dd2cIFx3dTbwVnD/Jl2sB2sBvyzbQfV0W4s
O66bejs4d2X+cDt48M/kqU1HdsNW3RLXedNvB/VSXodz2sEBYleowDvHXKZIXHcT7eAiYD6jHVS2
HSSrQZajOvjnNkayIXHdq3HdpNrBpSjze+1g2abtnvyyTTjqE7shcd3fHpNsB/WCXoC32sEy7Vpl
2kbbuK6RuO5tdZ5kO7gomF/Edaft4GlcF8ow/4ftxofbQXWBdnBxMJ8Z14UyzH8m0J9sB2E31Mjt
4BKVWYb5x4/rPtUOam4GNeK6zhshrlskzB+J62SY/zy78bF2kMFu4KHZbrB//npct1iYZZh/XKA/
0A66uC6Gd6ZLO4X+ely3ZGWWYf7x7cbr7SBdsBuRilwGTZYjDAf/bMaI6xYN8ztxnQzzf8JuvNoO
Dv7ZZs9DzT1qXLd4mN+I62SY/2tx3Uk76GdQXl0R0NraDX3eML99wyNxOEedBWZPhvlHfvgv4zpa
NJvBP6vX4zr8XHMc5sfHjhcj9BXSnaPOAvMrcZ0M839anfsPxnU23Qht3R2w3bADSVgMDnbjHO8s
MP/dbshWX98Q10UndXek4yG6a0IeSNJQ8q7zYTeCs9INgfkMuyFbfV0mrjsOImFc1M5Ax5EdGR0y
aLYcfnfWrVYC8zt2Q9rBy8V1pM7wzJh7LuMwLuI4KZI4LaIwKUNM2hlATW+IvbHq3L3tmwXmN+yG
tIMXi+ta1dRZWJebsMoJ5ChPonSfJtk+L1f7JMnypEmKJoxDxYtCbdU5UN5byYbA/I7dkHZw1Lju
AHQTNGnZ1HVYlWUeFvusynd5sd8W6X5VVau0TjJ6LuuSPLTqsG6hVwXvbW9ZDYH5TP8s7eCX4zrf
woxVoQkbE5AANCm9s2VlXa2KuliVdbkqqzKt6jKpa7JucUuCYGjh0vG7Wn9Cs8D8Bf8s7eCX4zr7
xUy2y/RsOXBvJRbOeN4I6jolQcCV1Db+pLUHwdwZev66oMfr4ClfFoDjxHXSDn7dboBq/PPZbiDu
5NgOcadpbQmFVKhtQ0NWjt4BFQKQDgEdRBk2retFmceO66Qd/KTdoH81cg2PP+IdC/cQ0rtXB3AN
MmnF72ZdF7A9Q5FoJ5yHS2AeM66TdvAL73Ig0on0cA2hh13Z2W/eJ965BOaP2w1pBz+kxX5/AjLe
4TpctJ62Y59civDmMHwbFW8Ug8k5eo5RZWNY3z/+HQLzJe2GtIPngRzgH4s7SQJU2n5LEGPGGcP5
mG8uo+OdKI29C0W1PKzv4/5Am+7xO6MvacZF7Ia0g++DzF+xUFePIUaVXdvmLyy4LAnjfRLF+zRM
8DFHE2jvRAlruwMSdg4NnDr7b76PCcyftBvSDp5nK8gmAGbYiRYzGaFSDHISRvs0irdZkj6vkoyu
1XOWrLZpku3iOM2xcYy2+9O1dmDf7z1P6uyL2Q1pB99TY6+j54ttBVsKgKw0KXK8z+LkeZ2ufm3S
9a9Ntvm1XtGVbZ6ydLVL7XwGWw5U2b6D2X9neSEwj+CfF98OvqLGAY98BgwyKXIZs7WICdZkS2r8
uMnWv+6z+//erx7+e7e6/3ezunskoEmdV3v6fSVuduVbqdxi0CmzlCaX9M+LbgffUGMe9Qx41BMg
wx/voMirNHtiRU7v/iWI//ewefjf/ebh37v1/eMqXT+ncZZjFFSryG2D6+4LhL2TeeaLx3XLawdf
Rm6kxsGgxuSPtcLMsi4jZRd65I8tyMnq0VqLu193qzsA/O/92oK8Wd0/Ecw7jIGGYcy7hv6myr54
5m+P62bfDv4RufkHNQ4YZPbGWOjldqGXuIWeA3kFkO+hygCaIL57Wq/usADcsSqHcXWI5/gG1/dV
WWC+QFw3+3bwtcjN91ve7CXAVrYaaQXbCvjjdZKR4q4e18n68Q6KvHb+eEVqnG3Idqy3abrCTHMR
RwnGPg+H+hySDF9uaP1uuzHfdvDNyI1BrkLYCo7dYqvGcfq8Jn+8Zn+8weIOqvx4xwu9u6fV6o4W
givYCgI5KbHeGLYceAmybDVwZbsxm3bw/cgNRUgRa/bGW5dWEMSwFZtHjt6sR36EncAiD2qcpat9
EmdIOMrQ3vdnj4nAO9wHQRaYL2g3ZtEOnhm5DWqc8CIvBcROja2tIIB/sS/ONk9D9AZvnMRJweUI
1NidRnXY0eiDIAvMF7Ibs2gHPxi5sRoTyPDGd7ATBPHG2YoNLIX1xjvc54e0gryxuwsb/thuzeV7
vAbufFtbyy6gU7EbN9sOfj5ye7KNHhZ4dxZmW4IMaoykAhAX8MZuS1u7v5zzx26QqB+ey48+BOYL
++ebage/GrllB5AfN0d/TB4a3jjN7Z4Ysdto3J4J+BVbITB/s3++mXZwpMhtzbaC1fiZrAXUeB9H
aenUuD6qMcqQr9kKgfn747ppt4OXiNzYVmS0yFthkYfc2O7D/GKR91VbITBfOa6bVDt4qcgNg0LD
Ik/zUcOsxmMs8gTmicR1k2kH31rkXSJy8+0B8MEF1Fhgvo7dmEY7+MYij29lUtOL3ATmiduNq7SD
ZyzyIm2H5xG5pRign0DkJjBP2G58ezv4iUXeKiE1nkjkJjBP1G58ezv4wUVexos8q8brdBqRm8A8
YbvxLe3gJ+cqNsiN7X15j85S/Lp25CYw34B/HqUdZO3DX/oHNUNS8eG5ijsHMS5AvHaLPBe55d8d
uQnMN+Cfv9oOkihzIIGtYhnok4TjDzX+wlxFhrTiipGbwHwbcd2n20F4575vfUa4B9KeOgX5VI2/
OFexS5K0SK4YuQnMNxjXvdcOWquB/JlgHnbI9HBmSMfbaw7KbO1F4CI32IrPzlWQrcAdILgnLzxd
5H1v5CYw31hc9247iJq7a+ky4aFEcQkGJjdM16P6DthEO2thF3lHNSZ13dm5Cl7kQZHPnquArbhW
5CYw35bdeLUdxMbbpMrIoCNjuETBBt1uAIl38e4D0xgTsPUI7F3MnFjgNCccflMcF3nZEeSJzVUI
zDO1G4d20BhF1oLkmneSDzm+I5gRz7Eg0wKPoDVVo8iemMgdIkkLS3hkXWOh5yK33weEOKXYPE5p
rkJgnqHdOG0HMQKKcz0Mq3Gr7RkfneIimxR5yIsj3WTw04Dc7n1Mf4fWldvTbbeKOa14cmr8uHHe
eEpzFQLzzOzGy3aw62O0g0FncDRCy0cl4KBT/AEoMo7sjXVUlk2VkXpHvbUZPQ5bx57H5JEJ5mzL
8xUZzx2/VOPJzFUIzDO0G8e4LmzJF9fc9tmzP4IBZFTG2FQQJ5smVbKvagszbAb5DLIe7L3LNE73
GbaLTTfPFuD180GNJzZXITDP3D97Kmz6sLdn6PWdBRnKyzYiquMwybMq36JUwSlNfd85ZSbQQwI9
SnOCeZelqy0Wd9ziMciAeFpzFQLz7OM6bbTXNzaCw4Ac77dmlA65AgeUZVU8o1jBUWMW5sDCHkYV
2QycRZ3DE2fsi7HAs954anMVAvMC/LP2dOvZ6ALzRD3DrHQbE6yYXKtrPgQSZQpyZp6lg1VArIbj
FGLs3xYnJal4SZYCB6zXtxa5CcwzsBsEdAem6UVqkV64BKMbhpPiqOah/tY0IZ+bZ0sThhlHKPBh
N2EEeCu3/VWDbWJvLXITmGcGtB1bpv8CQLIRyIGjqNF8kilKFD4E0o7qc8SHY8gIes1X2LrDbniB
Nyc1Pn34gs50H+yf7fG8dCGq46N3Az7N9PCRQcZoRsCbvjKbPs7R6+2u8zhbj78Ahh3oZ6XGAvMt
Ao1zS/t+ADuAcPMB6V6HybmgP7nThGeYWXXZY/NNVodDboaDbmaixgLzLQLNS0ML9VGte99Nznm8
PYx9Pd3Bj5xZ9wPArMQO4jmpscB841CfgHsE/Ph6ul88gnsEfJ4QC8xzUeuB1t4q9t+O5J0zwPKQ
hzzkIQ95yEMe8pCHPM5//F+AAQCEuoM2bXj4WAAAAABJRU5ErkJggg==" transform="matrix(0.24 0 0 0.24 147.0293 66.4614)">
				</image>
				<g>
					<g>
						
							<rect x="156.507" y="85.752" transform="matrix(-0.8185 0.5745 -0.5745 -0.8185 348.2877 62.1079)" fill="#FFFFFF" width="15.653" height="0.627"/>
						
							<rect x="161.521" y="92.897" transform="matrix(-0.8185 0.5745 -0.5745 -0.8185 361.5095 72.2203)" fill="#FFFFFF" width="15.653" height="0.627"/>
						
							<rect x="159.097" y="71.142" transform="matrix(-0.5747 -0.8183 0.8183 -0.5747 186.3163 254.7616)" fill="#FFFFFF" width="0.513" height="15.655"/>
						<path fill="#FFFFFF" d="M171.648,110.111c0.543,0.773,1.607,0.961,2.383,0.416l10.01-7.025
							c0.775-0.545,0.963-1.611,0.42-2.383l-3.873-5.52l-12.812,8.992L171.648,110.111z"/>
						<path fill="#C7372C" d="M161.812,68.849c-0.543-0.774-1.609-0.961-2.385-0.417l-10.012,7.023
							c-0.773,0.545-0.961,1.611-0.418,2.387l3.801,5.414l12.812-8.992L161.812,68.849z"/>
						
							<rect x="154" y="78.445" transform="matrix(-0.8186 0.5744 -0.5744 -0.8186 341.6791 57.0665)" fill="#CC5440" width="15.655" height="8.099"/>
						
							<rect x="159.011" y="85.587" transform="matrix(-0.8186 0.5743 -0.5743 -0.8186 354.8982 67.1922)" fill="#D16B63" width="15.656" height="8.101"/>
						
							<rect x="164.028" y="92.731" transform="matrix(-0.8185 0.5745 -0.5745 -0.8185 368.1198 77.2747)" fill="#DA8E8E" width="15.653" height="8.1"/>
					</g>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				
					<image overflow="visible" opacity="0.4" width="202" height="165" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAACrCAYAAAAq03MQAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAG4tJREFUeNrsXYlC48ay1dKt3WaS
3Pf/X5gM2NpbLb061TIYBmZYvAirOlcXMwEmSD46S1WXAk+WLFnvXoGcAlmyBDCyZAlgZMm69lJL
/o8bp9E//nwap8fP/cCfniHfDya5nLJWB5gDSAAOOrxpoo/edAQcvKY/sjNwPAcca+10AJGAR9Yq
AAOwMFBmkEzjGPBH95r+3HtiGN9z4AjCEaDxg2D0LX30/YnA4wM8AhxZNwsYgGW0M0AIKHYcQiKY
YMRHAGcCs0z+OMuyIAA4gjEAUOjLwjCwAA8+BwPRv6EvHUWuybo9wDyBBUCx4WAGNU1jaAajBjso
Ao8i1gifA4ZAgiNUQxiElj8+vg6tFxCeLIHJB2mNAhpZtwGYA1hGAgpYhQCijTF6sH3Umz4ydPRD
HxFgFH1NwLZlBgwxyqBCbbTSJtJRr5TutYpMOCr685DAo+xBqtHfMwrbyPrWgDkGCzMJgaXtm7jr
u6Q3XdJ2TdqbFh8TAGkYiHmIZeBZiF0sAWSIoriLNB9tEqVtpA1/PhKIlPJM6AUWrDNa+B5hG1nf
FDDHMuwAloaAAZA0XZU3bVXUTZnXbZN3PYBj4sEaDT8DwCgFFlF9HCdtrJMmjdM6SfKaP8Zp40Bk
O621GQPItRAex4JtDomaAEfWtwDMYxo2exZ4FDBL29VZTUApq4ftvt5vq3p/VzXlBqAhpkmJYTR9
bwCmINYYSIZ1DJYkq3BkXbPvs6IkdqqSOCPgDCqyAA7JtVEbYOYg0yQUkPVtAAOw4I0PtoDBh1fp
+jYlsOQAy0N5/9fD/uHvXfXwF0BDzFO0ps2MHSIkaI4dwoG8S5foBKxS5mm+z9MiJ4ba4XWRbffE
SFEcJY0diXFU1BPr9GPoDcoLBwkFZH0LwMxSjCNiJF+QWX3fJgSKjCTYxoHl/p/7/c//0eu/9035
V91Vm7bvMmKYyE5jeGAYB5i4TqOkarp6Nx9ZZ+7SzvRJTv4nS4oS/gdMBCZjfzPqQEIBWYsHzJMU
I7BAitEbmeRT3BJgCCzFrny425W7vwgs//zc/fd/u2b3z67aE2CabT+Y1AwmGgEY1FnI9OtQk9zS
TRLFVdO3OYGOGKbNiLFSFxh0Sde1iTFtnKVFNdisjiOj5pCgl1BA1uIZBgXIca619EOv6Q2eNG2d
AzAlyS8CzV/ELP8ALPflw//Ktv5BQNh0ZkiJISKScaHnu5SMZJmJtWqJTXJioLw1HbFLlxEA6Wgy
yDySZg44fZfkWR+R19FJNDTEbq2EArIWC5hnxcmDFCN26cjMIxXbV/vtvtrd7euHv3b1/u+Havf3
vq7ALncEhIL8SzJYq4mdQo8bynxU9wl3Ickvk8ZaI0lL+r5LuwEHWIaAw0edPTFOTsDZlMRWlYQC
shYJmF+k2OiMPt7EdUNGvy43YJey2f/Y1+WPst3/qNvmrumbbWO6TTeYjHxIbMdJEUPxlgT0koVT
MAy+jYaJjpEOi2OISb4lbU+AAdOYLgXbtAASvS6yFjWemECDmLqUUEDWIhmGGyrRI2bHEJV8ZhdO
xsqiqvfbsiZ2qfYMlrKp74hZNi1JLXiXgdllRKSsJjSKTeixJOlEAAoDfxinSdHPVQRGDdAYa2Iw
ErwMQAKZxq/p7yNPA+bhUID+PJZQQNaiAPO85jKEA/kWNvrMLgBLuYEUA7uUbclgITbYNH1fsNGn
N76xY8RgIXZ5BAzLMvp89AMCDJmPKcTX2HFE/1nUDzamvynuLck0YhxIPwYPsQ5eQ55JKCBrcYB5
VnOxlqRYh5pLQr4iJ2YpGCz17gfYpWpL1Fy2TdcWhjyIsZZk2KjRiAlQuLZ/10v2BBoHIPonIDwR
eAw5EavtNGqEBAS4eBiGmDxLAo+Des7MOuxxNvlHQgGwTTBKKCCAOafRZykGsNjZ6JOnSAGWsim5
mu+kmGOXpm8LMuxZP9qEbusRZNcRWJ5tqZ7ZZt5R5hFY6N08BQwuZh2wzQjgjCzTABiSYmk/kByb
QwHItPeHAmAbCQUEMGc3+kM4Whh9+JYu5Ri5rdjo79H+0lZ01GAW9i2QYZZkFZkT+Ba8+YMDs3jT
0e5Ln3dY+sfAob/Zf5RpDLbZ29BhDv7mi6EAZJrHEbSEAgKYExt9btsnow8p1nYd94sRq8C3bIlZ
0Cv2Y49UjKQYySXnW0hCASyQYmCOGSz+M7Acg+cJOJ4loATPZNpEBn7CJrTQhQIk0yDRkKZ9IhRQ
YdRL7UYAcw4p9rzmMvQRzDY6kNFQyTEyvAtJsapl37LpjMkAGPIfbPKZWRwo/D8g8wCc+e+n9+0M
sgmMY6fgEAqwRLOObT4TCmAbgdRuBDBnkmJv1VzKLR13JUkx8jGIkLdobekAFq6nIEKejtnF+4Vd
/gAcfB+xjX/MNvA3ajIhwVi9PxTYZE+hQBejCzqlg7635TRtiozUbgQwX5Zib9Vc4F1Ijv3gGJnk
WE3sQm9M1Fu4OHkcIY+uSOm/CywvgTPLtAPbAHxENXOS9v5QgP0N79GBz2nSPC32xINRMmR1rE1j
Y7eBTWo3ApgvsMvvay5Vu2d2gdFH20trDBt9VOsPUuwRLJ9H7TO2+Xwo0M69aS22DmQdhwN9kidN
maUb8jdpI7UbAczn3qNfqrkMXHP5lBR7J9t8JRTojho6ORTIitTJtM1eGjoFMJ81+l+ouUAaTV+T
YqcPBRKEAgSMjGCCCDqThk4BzImN/tdrLmf7bT8ZChjtkjRp6JSlTvdePKq58D4X511OVnM5NXD+
HAoobCc4dEE/NnQOXJ+Rhk4BzAmM/ot9LnWzL05eczkT27waCrzW0Gl6bB9IpaFTAPMlo39cc+l7
zBSrM5JexdlqLmdgm9dDgXM3dEoosBrAHBv945pL49pfHve5PNZcsMfl1DWXM4YCl2nolFBgFYB5
WXOB0XfjXWd2aY73ucw1l77bnKXmckmZdqaGTgkFVsAwzug7dnneXFmyFCPQ3EGOzTWXDYp/Z625
XEymnbahU0KBGwfMe5orIcc4Ru6IXRpmlwJG34xuU9jZai5nlmneGRo6JRS4YcC8d6AFgYWr+WVT
YZBFQTIlpzdSehhmcfaay5lk2mkaOiUUWBXD/Lm5kmsuAMvd3Fz5rOYyD7O4TM3lDDLtqw2dEgqs
BDBfaK7MuKJ/rZqLhAKyrgGYEzRXqsUafQkFZJ0SMIturpRQQECzJMB8m+bKVYYCMvppkQxz9oEW
twCcq4QCMvppUYC5+ECLG2IbGf20MsBcdaDFjbCNjH5aGcNcfaCFhAIy+uk7AGZRAy0kFJDRT0sH
zCIHWkgoIKOflgiYj9RcKvYuN15zWVwoIKOfFgOYY6M/khxzNRc8Fvz1mgvk2GpqLgsLBWT000IY
5tjo98+8C0sxqblcNxSQ0U9LAczLij6MvpljZDy4tayJXRrEyL/WXEg2JKuquVw5FJDRTwthmGN2
GQbzxC41FyndLkpOxSoGy6HmQl4nXmXN5bKhgIx+WhJgjtkFJ5XoXHfzHv26q4uq2W+qutzS6y0G
WjRdUxxqLsM4P7RVjP55QwEZ/bQMwMxyzAO70Adug4HZN5gC0xJgmrqgY1P3dLT1pu26Ao8D55oB
P1qP2WXdNZeLhQIy+mkZDDPN6dhoA7p7cZyMOxNOetNWeUMsA1ZhGUb0755wbKXmcsFQQEY/LQkw
ngOMB6YYbWich4mghWEmDzUApn9U80kSzCY/nMToX0+myS7PK5r+cUSjZfDoY6yJ6MTT0ceHuxeo
H2A5LlBO88NZhV2uJdNkl+dFAQPD764L3vQTLgT7GIsLAHk2DrgIqAfAbConB8bwESwCkqvJNE92
eV6LYdwFGdFH5gCEiJmH9eEguRaMnjP1ApDlyDQZ/XQ1wPgvf3H+nE8KKNnzx4AOusOMAR0+v56N
j3+Q1557DLgA6qIyTUY/XRgwAIVn3U0rmAFCd5JRKQUNS0c4qFDRyVKGPwYhtscO4RQMiKFxkQhE
3mOsLKCRUGANpp/uGnQGghHUSweDhO4snVZxSyerSaKoaTpNJ061xqrY+qMiF+Pj/oKL47Mdcr7m
oLUFOBIK3CRg6IzjrDO7wOBpFRk6QV0SJ00apxUfXbZvY67B8JwxXCicKPq/iVtjvFG57MALHruV
BTgSCtwkYPwDYBSxiyJ2oTMZJ20Sp3WW5mXebR5wMo3tY9ypPHcxJn+gu8tgyNMM4zD60ALqBdt4
ItPWFArc7uinR8Dgl6Jf0HdGjmiCAAOTh/3iWZJXXdbujOljxMszWNjcEwqsMu0Q+sHQB8EQ0Emn
04QajYJ2pvuc6wDgLgKRaesIBW539NNzhqFTCXkFsw+jr+kEELs00K6uVcYqjpdH3DgCBpUOVV+1
qlcBWUfT9X1gUtcyM8Tkashk+rw/ZnRtM4GEAusJBW5x9NOvpt+fPQzdJTBMwWrbprENHVBGvknh
3yM9I8D0OtSdVrqLlG51r1tM6sfw8dCnO9Rok8AS1Ag0r8g0YZsbDgVudfTTM8Dgl8BdAHcEUOoU
eiaOYn8uaPqHFA1gAvvQ3aSN67hx6Rl9bKqmDnUbma5ourAIByL3IEgOMk1CgfWEArc6+unVLcps
/sEinhqO2164YBkQWEJtNFEvMwudGNBxXNOhCT66aqq2bkiqdU1P9yDT52HAezXSwLfWWD+SUOAW
Q4F1jH76BTAHluG7QIAvoF/Qix2IGDChZe9Cdw4CCMm1tImrhBgmqfkfTQe9rtqy0Q3JtA6gaQsV
hKZj0NBpn/fPSChwO6HAWkY/vcowx6DxCDSa6zPeU/UfgFGRUYpjZ2YZAk6NgCCJ8DGpY5JpkGyV
rpu60y22M4c9WcghyCQUuMVQYB2jn94c5HcMGrr3TBpGbUQ/mUvHuAtAKU5EcBLI64B6uWaTVAQY
HRPzxA0YJ9L7luRbS18PvukwHVNCgdsMBW599NNvZysfQOOBMCcEAXTu6K0dWGYaztoJCFzg1AQa
rZMOhU42fPA2mr2NA48uGyRpdai3kek6CQVuKhRYzeinPw4jn/+Dp8MIpkPncjjyXYHOmh5czUZz
UyYxTReDcXTUoqUmxhElDZ2YORQoJRS40VBgDaOf3v1ApcfIGeeNsANrCG9jfbr1+AG30jBw6JcH
48xatUuipAXDIHZOapJr/FpCgRsKBVY1+ulDD4U9/AcfexvHOOPoip0uQXOhgDZaRyTR0oO3aRA1
4q4iocCNhQIrGv2kPvNNz2Waq9kEs/Z0jZvz1gClUK/B9oD+KRQgwEgocIOhwDpGP6mvfPOxTMNd
AKFA4BHbkEwLeHuAHiQUuP1QYE2jn9RXf8CRTBt5cuajTAvHwTVxWgkFVijTbnSXpzrVD5JQQGTa
GnZ5qlP+sNdDAfgaK6HAymSad6O7PNU5fuhbtRsJBdYj02519JM65zmUUGDdMu0WRz+pc58/CQWE
bW4pFFCXOocSCkgocAuhgLrk+btGKGBHn+9kL0IBkWkSCiwfMJcOBcyIxwjigU8y+klCgdOEAuqa
5/AsoYDSm6ine08f5nhYrYx+klAAoYDSAChJtKNQ4DOgUdc+fycPBRoOBVodKjoIODL6SUIB8sXT
FPeYCx56oT2EAp9hG7WUc3iOUKBqCDhGRj9JKADg2JBuqmEItjkKBT7KNmpJ5+8UoQACAXI5AA4P
4yDYbGX009pDgU2J57Vaeo/gJuvpuMcIMcc2nvcR0KglnsOPhwJupkDMoHESDcM4Zn8jo59WHgpA
pvXpBl+nEx79BLaJA+3FPQPgA6BRSz6HHwsFoh5sA28TQ55hGEeEPE1GP606FOjnQMD0BK4eQFPs
X3mIGF3fMfK1fj9o1NLP37tCAQwWpH8iHi7I86BbYphGRj9JKOAkWp9gB6gxJppnhBNgLEvvJHbT
XI+ZxpufvPctAfPuUMBNsRlCfgCUCwRk9NOKQwFiGj4cWBJ+CjhJMjAMgeXwqBYPT9vDA8R8L2LZ
j58NK/AWy6jvdP7eGwpEHEFLQ+eqQwEwykCqYcBQDgZLNDBgLJ787fuzT4a0Z16ZPTJkP5TMW9JM
fcdz+KdQQEY/SSgwThbgiJh16MBkm/kJ4KxSuPpPykQpdDq75BXvHXhkvhH/+oDk7wuY38k0/MLS
0CmhAHd1WAvWwTONQnqjBM6muPng/LAwFXcuKIq7gT7XozZEMRbTXb+9h/mYTJPRT+sOBbwDcOid
P5EEO/gVYpQw5IccH4KgNEkreNxo4MdzhCrENOTJf0uWqVs5hzL6SUKBp1CAXA3dMh1QpvkhYP6o
OtXDs6ZRXaVttW+7IkNXAHmbCGwEfwOw/e6vVLd2DmWXp4QCM9vw/0BCuMGZITBc1BxiLmzOkzlj
Y3oXNY8uasZT9lz5grzxKz5G3eI5lF2eq5dpnrt5jR72Q1kftZrRhQADAoBBWzpGfmYrAwXJGTPS
hGKm9/b1Urd8DmWX52qX76JjnyWZ8zkjsz+Awd6GPv8TOFYHmLdDARn9tAJf8xxBeNix74/8FD0+
eM//6HsuQvaPqvtvRcqrAMzroYCMfrpdbvFnRebOsu/NAMGjjgPuCMHN0fDeGLcbk1S625EZYgbA
DBbfv8E6jIQCst7GjWOUgEwmXXOApNchHmJMklpFHT8BnLzr/HBjgxsmWmRwE8XNdPUMI6HAethl
zpKdT/V9AktIHjUkkGi6XlGDvVL85G/yqKjJhI5x3N7/EEVL/82dmGrN51dCgVu9ruxR6Kbn0/UL
Dbarx3xzi/Ao9DKN0zJPszKO4FHZr3a4zjwow3u7LWb1gLlWKCCjn87HLkfG3qoggELoYmIWuqlV
WZLusyjd45HoAA59XuHaaR4ESLIsgJdxAwAFMAsJBWT009nAcijrO7CEAEvIYKHrUqVxss/ijMCS
73BkaUFMk9PNDje/CAMyhoMcO76RCmCuEQrI6KdLrOnR6JPJD/0AJp+uhwZYyjzJdkVSPOTp5gEM
Q6ApkyStcc3oWhrXqRy4uFlM/5VDARn9dFGjrwKfVIBik09sX2VxOoOluN9kAMxmlyZ5hV25YBdc
xxBdykHwx5GyApgLhwIy+um8Rh9gCTlCVm2iIwJLQmDJH3I6GCxZsaNjnyZZFbGkJjmGekzA+2N+
yy4CmAuFAjL66TJG36VigYmCsI1VVKdRgjRsx2DJN/c5AaZIi132yC5xh3kQuIZ/8i4CmLOHAjL6
6dJGn8Ay4HzS+W/gW1KSYjlJMYClyLb3m3z7gFGydBNDOtby0HJU+oM/excBzEVCARn9dHmjDymm
weaz0Ycc2zw4sBR7pGPJPNBP0Y2NJ2B6DizfdpDfTYQCMvrpYkafW1+OjT6xS5aQ0U9zGH0wzAPJ
sD1Gx7owJu6Ojf7v6i4CmEuGAjL66SJGH94FBcoodAXKlNilQL0FciyDHCPvwuySV45dnoy+/5s2
GAHMFUMBGf10XqOvg7CLIcWi2Bn9+JCKbXaou+ChSzjPkUs0XbMlrljI/kUYZmmhgIx+OpvRNwej
D39IkncPZiFGYTlWuBi5dGmlezoZpFjwQSkmgLmgTJPRT6c1+sHTJjDyLuHB6Fd07vYHo1+kG5eK
EbsALK6iH/WBO/8fMvoCmKvINBn9dEopBrDoMOjZuyiNAAUV/X2RFvcAS5FtnRzLNmXqAPOL0f+W
TyBbj0yT0U+nlGJoriTT3qO5Mo3m9hdIMLBLNkuxJGMp9pmKvgBmITJNdnl+Xoq9rLm4DWG6Ridy
nqS7TVJwgbLIXIMlHqTERh81lyOj/20fCrtSmSa7PD8hxX7TXFmCXTbJ5p6bK3MuUkKK7Z28jbEd
2Tijj23I/vd77Liwjezy/Ph5e7250rHL3C/G9RZXpERzpduCHHdPRt//0iPHBTCLCgVk9NOfjP5r
zZVFmpFvYWZ5bK7kfrGj5kr0i33F6AtgFhkKyOinPxn945rLuZorBTASCnznUODtmsuZmisFMBIK
fL9Q4D01F26udDUXAsv9qZorBTASCnyvUOCTNZdTNVcKYCQU+I6jnz5RczlNc6UARkKB7zP66Us1
l5fNlV+vuQhgJBT4FqOfllJzEcBIKLDc0U8LrLkIYCQUWObop4XWXAQwEgosefTT4mouApjVhwIL
HP204JqLAEZCgWWNflp4zUUAs/ZQYJmjnxZbcxHASCiwnNFP36DmIoCRUGBxo5+WXHMRwEgocK3R
T0/AeZGKLbnmIoARmXbN0U+/MMthzOtSay4CGGGbi45+Om7o9NzzJB5pBmDBtH0e8xqEvfMtz2eL
XbvmIoCRdbHRT4eGTvI3FmwzsTxzvsZ3yRgAYwgsXRSqhkDIc8UYLAupuQhgZL0q087X0BkaBo1v
NXwNjplfJvr77GOEHM0PbU340Xr3GwCG5dh1ay4CGFlvybSTNHQmbVnPocA2Un3b9G1ubEgSzcZ2
JNCM7Glg/ydiCZJiCj+rzqJkl8cp0rCfWzo2+d3PLUkxAOZxttgVai4CGFnvDgU+19CJboGSW2zq
rtmAPVrT5WYwAE0EP4MAYE61+GlhMPkkxR62+ea/bUpHfofjZ86T97d7V1Sdjb5WwyVrLgIYWR8M
BT7X0Am2SaO6qqN603Rt0Zmetw1YaxECBHjDk6waIONcvSV/uMu2/94Vd/8yYIrt/ba4e0jj7EmK
EcPhseDXkmICGFnvCAU+3tDJ7TVRWtVtWVZNtW3ipmhNm/XGJORnWJYxYMLQQNqlUVoW/Djw7c8f
mx//3m1//EevYfbLeer+44NbL11zEcDIOlMo8NTQCQmVNVm1r9IKCVfT1kXbt1k3dOlg4WNsCNNP
csxoZpi0ytNih0SMWOXntvjxc1Pc7ZCMzTHy1WouAhhZpw8Fnjd0dsQKjeuCzqq6KYu2a7O2b4hh
+oS8jD5EyyzJIOeipM7SvORELN3sAJaU2CVNUvycDlLsWjUXAYys84QCAfsbQzKtj2eJlqVt1RAQ
CDBp17dJb7oYDEM/gAETMGB0j8o9mj6JjSoUJ+FbAJZIJy0KlPBMB6N/bbAIYGSdMBTgMABJmtEM
mq6FB+n6LjamjYldIktyjDzMgWHYC8VH9R0nwRLs0+kcWJ56xa4txQQwsk4aCpBAs5ZBgCRNc4eA
ITik8aAhxQZr2PC7bn4UY/zZC0XGRdUReyH3aArFzOIf+ZYlsIsARtZJZdqzTuiRQGMjZa0N7Tgo
S8yC1y8AM4aPIHO1HrS9sGdZIFgEMLJOKtPmP+Q0bXRv/IGoh76I/iUBZpoO+8UYMm6nZeAANqdv
LO/Y4PvXrbcIYGRdRKYxcMIAei0Ig4ll2+TNx0h/PHkzw3jwQBMXIj3nh/wZRAe/sjSwCGBknQ04
oRdaF0U7RgFgvODX7zmAhF8vGCgCGFkXkWq+9zzdAoiOwfHye5a+BDCyzg6cF2v61r+TXFZZsgQw
smQJYGTJEsDIkiWAkSXrNtf/CzAAu5WCzvhIGykAAAAASUVORK5CYII=" transform="matrix(0.24 0 0 0.24 140.2344 78.4224)">
				</image>
				<g>
					<g>
						
							<rect x="152.206" y="94.878" transform="matrix(-0.5278 0.8494 -0.8494 -0.5278 325.3475 9.4988)" fill="#FFFFFF" width="15.654" height="0.629"/>
						
							<rect x="159.618" y="99.484" transform="matrix(-0.5279 0.8493 -0.8493 -0.5279 340.5987 10.2687)" fill="#FFFFFF" width="15.654" height="0.628"/>
						
							<rect x="152.415" y="82.788" transform="matrix(-0.8476 -0.5307 0.5307 -0.8476 233.9819 248.4381)" fill="#FFFFFF" width="0.512" height="15.654"/>
						<path fill="#FFFFFF" d="M176.186,114.447c0.801,0.5,1.857,0.252,2.355-0.551l6.457-10.389c0.5-0.803,0.254-1.857-0.549-2.355
							l-5.727-3.561l-8.264,13.297L176.186,114.447z"/>
						<path fill="#4E74B7" d="M150.965,80.34c-0.803-0.5-1.857-0.254-2.357,0.551l-6.457,10.387
							c-0.498,0.803-0.254,1.859,0.551,2.357l5.621,3.492l8.262-13.295L150.965,80.34z"/>
						
							<rect x="148.502" y="88.839" transform="matrix(-0.5279 0.8493 -0.8493 -0.5279 317.739 9.147)" fill="#84B1E0" width="15.651" height="8.098"/>
						
							<rect x="155.91" y="93.445" transform="matrix(-0.5279 0.8493 -0.8493 -0.5279 332.9825 9.9056)" fill="#ADCCEC" width="15.657" height="8.099"/>
						
							<rect x="163.324" y="98.053" transform="matrix(-0.5278 0.8493 -0.8493 -0.5278 348.2125 10.6308)" fill="#C7DCF0" width="15.656" height="8.099"/>
					</g>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				
					<image overflow="visible" opacity="0.4" width="207" height="131" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANUAAACFCAYAAADMzr10AAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAGYNJREFUeNrsXQeb40auJLsZpZld
23f//x++t54gMbMPBXRT0iSFkTQKwH00NXFlL+uqUAgdRRoaGhoaGhoaGncU8U/9waMbN/58N7rN
NxbHb9+dM7HRvzENBdVHQGIA4U929Dpy8j745sJ7oq+s8BbL23RvgKYg07hfUE1gck6A5BxdIwAV
O/78yJ8P78nhCzGzleML2Fu9DkzmFGAadwkqAAogEjCN8eiccW6IxzEyoxvo9WjcyJ+PA7A8YJwx
xgPJjLjTx3T3n2Nui5XFNO4LVAFQDkBi4AxmGEY7joMdcA1yp4/NOBK4oPQoTCzMZIwdbWxGug8A
lMWdPo4NPidA22AyZTGNWwbVG0AZAg8Bqk96urq+S3H1A119lxC4EgCLQGXkTQEsBCJrBmuS3tpk
SKzFvWdg0UV3ARbucewBtpXFFGAa1wmqt4DqwUoEHgJS1nZN1rR1Tne6Wr73fZsyg5EUFKKJRzCS
TZI+sUmXJmmXJFmH13TR59I+oa8RuBhw1jOXibaymMpEjZNHcjKwIociyQeGAht1XZvVbVXUTTWr
m+Wsoqtp6rJu66JtCVhDn3J+BVBFMUk9MyQMprTN0qxNE7kyupI0bQE0C3B5oFnL7LWdxWCJOAEZ
AOZNFAWYxuWCyrNUxKYE5019AFRZVYv5on59WFavD6/Ll1/Lejmv62redE1BwMsGN1gwHLMUyT1i
JLBUQ6CiK8e99vcmJaDR65a+pxUmE4BBLhKLDdtZzGwAjN/7OKpM1LhIpmKWgpQbIP36PoXEI4Yq
FwSml+XTXy+vT389L15+LyoG1iOBqiRQ5ZCJeMoBAAaVSdZBVed0AVh5hnu+ATAGGVgMzCUg6xLJ
xwRkMeVnDLLPzQ6Qa+zeAUxBpvFzoNpkKTYnkpbypaari6pezF+Xz7+eCVBPL3/+8/T69M/L8uWv
ZbP4VbfNrBu6HIaFl3+OGIaYx3apTQk0aZMneZV5UBWZf53kTZ5ldSqfp9d5kyY5mItBFlhMpCKD
i/MxAlavMlHjWpjK16McW+TDgFyqySl3KoLkI1D9/e/Ln/8SqP77XL38vagXv5u2nbUEKpKLCegC
7h0ziyFQJQmAQWAhtrKpMFWaeVBl/mMBW5blTQAepCF9fYPFkJMhR9vP7PhSJirINE4LKmEpB+kH
g8KS9ANTIZ+aLSsw1cvv58Xz33T987R4/s/z8uWfZbP83XQtMVWfk1xMpJkipgc19qaDIbZKCAxJ
Q/cmY4ClABQBKBVgMbjyTYAlJBczgAr3CWwtg5PY7COz4xCZqCymcTJQsfQbHbcecT41QPp1KZy9
ul6WBJ4HyqkeX6uX3y/V61+4XuvF31Vb/2JQjUNGDJBETp5WfpjjuLeesSjHohzJEsvYFiAT9gLQ
Asgo9/LM9Y7FsoLZzediAWDvzI7vykQ1OzSOzVRSCJJ8yg5jT0zVeZOiLqu6mlX18mFZL38tmuUv
AtkvAtRj1TYPJP3KfiBQOWdXrUoRF4CZsQhc9FAPBDDOs+gOZgHA/J1ZDMCoAbR1FiNQVWCtLCNQ
TWZH/qXZcZhMVLND4zTyz+dT6J4g+Te0DCoYFXVTzaumeqjaak4ge6i79qHpIfu6suuHguRiOrrI
hlalmAu1Ix7UwA4kB+nC/8BgxjMYycOE7wQqBpldZzFmpCzhO4Mr5GMfmR27ykStiWmcHFST68fS
z8XDAOev9x0Ubda0TVF3dVl3zYxez0juEZC6ouv7vCOGolwqHZxL0Wwb5J/PV6TdiJ5HfmBH7ohg
9uI2prhnBhMmgwVvINmEwfgO9pJcLA3ycJKFH5gdO8pErYlpnIOppMMcTDWOxFQs/RIv/4qWANW2
dYl6VNsToAYC1DDAmCBAuYRAmUj3ujNuTTBBTEX8S7lN3Ql78X0Ei/UA2EjsRWxGylBMBoM8zILJ
hMX44Zc87Cuz4xCZqDUxjZPKv+D6+fqURdtR17cZ51TEVMRQpQCsy8FQPcu9MaGfsyT71gHl//+b
Z61AVzKF5TjZmu7+4RyHOEres9gAcOGB7k3cepnI0vAzs2Mvmag1MY2Tg4qHDydgcT6VoAMd0s83
zRaUPxXEUgVkX89OHzrTnZXOdBlWXAfU2i+fPu/iOMwHO89iBnbjhyw2CsDAYqQKe7CHgTwMZsd3
ZKLWxDROCap1K31V9O0tj3d4QOGi12CpghgsI9ClsM9HZqlNybcBqPfoXU0H78VicWqIQ9fNjkNl
otbENM7BVCsr3YlBAekn+RSBquURD2IqAtbQ56hHidPnEjEmZLxe8jK33yjKDizmvjQ79paJWhPT
OJv827DSu0FYqmE7HQwlsq9D0yyYinKpwYn084OJ8RHexIcsttXs2CYTtSamcU5QfWSlY7IXzh+G
EdH31xJDIZdig4IA1YuFnqBAHKSf2yb7DgfZt2Wi1sQ0zs1UH1jpDCo2KBrv+jFb9ZxP5SL9RjEo
1qXfKePbMlFrYhpnlH9vrfRBpJ+ACiZF642KYSX9kE+Jhe7Ov8zzUJmoNTGNc4BqTfph3RimfHmh
i+yfYDCVTd/SFfIpkn7DyFY6/dVC+m06fz8RB8lErYlpnABUwUoP0q+HjY75qYGkXw/pV0P6CUtR
TgWWCgVf3z2Bn40Ocv1+UCZqTUzjlEwVr0u/UeanNqWftCVxPuXnpUIXBYHqAljqAJmoNTGNk4Hq
fRdFb/tBXD9vUBRiULTcOCuu34ddFNcRWhNTs+OUoPqoiwKuH7rSpYui9iwF5893UYyfdFFcivQ7
h9mhNTEF1ZdWerStiwJM1bBBAenXD9xFYd91UdxCaE1MAXYk+bdrF0UxQPo5hy6K5KhdFDcpE2+m
Jna3INsbVAd1UZD043zKnbiL4lZk4o3UxO6VxQ5hqr27KNZmp25P+p1cJt5GTeyezI7ksGdjzy4K
35X+Y10UVyoTb6cmdl9mR3IAoK6/i+JKZOI11sTU7NgTVDfZRXGTZsfP1cS0AXh/prrtLopbMjt+
uCZ2zw3AyX5/v3fWRXEzZsf5amLaALwHqO6+i+ImZeIJamLaALwXU73voiDQ3G0Xxa3IxCPWxLQB
+DD5t7nWmc/xbeUc33vuorgZmagNwGcD1WddFASosNuPHT/torhemagNwOdnqvddFGhNGkI+5VuT
AK5BuyiuUSZqA/APyL/3XRR92JXuuyj8br+eNyal2kWhZse9LsVJdgTUGyv9k7XOcP3kQGy/hozy
L+2iULPj+pbifAtgW0G1z1rnaVkmMRXJRIvDB7SLQs2Oa2wA/g7AdmGq7691VpZSs+O6luKMsWcw
n4PtBaxd5d/Pr3XWuEmz4xJqYp7FRhgfxkvFIBfZ3HATc+0EruQQK/0i1jpr3JDZ8YM1sZTyMMsX
ACaSESwWE7jgLloerhj5Xy3ajbW2MdV1rHXWuH6z4+w1sZXhEdgMUtF/LEBzaUfPL/KxKBaZGO0C
rGT7f6MrW+uscQNmx+lrYpSLeUYTsPGVErtlZZ3nhYBvzO0IAyTJOhslA2FK8q8twEq2Wek6kKhx
OTLxeDUxOIj0mpisqIq8rIqsWJbFbFnm5bLs5ouimKX0vFcuK4Mn0AIuMesvM9W59gKVDiRqXKRM
PEpNjECVCMCIrZZFToDKZwsC1eu8mL/OiTTmfZuOs0cbPIFYgE5vM6GHO/4yv/qKqXYeSGQrXU6b
14FEjTPKxAPNDos8LOUcrCBQ5cRSs7x8neXzl3pWPYE0UDbyLXYMKGu8GxjLBfR/xlbJV9JvlVOt
BhIBqvcDidP8lA4kapxFJh6nJpaKQ0gysMqLl6qo56i34lkGkQBMbK9bO8B+5/qWr2dFzBseJLuA
6sOBRG5N6tNWRj3yta507vfTgUSNc8rE79fExODIRBJWlM7MiCRKONvoBgKg6Os95Wfd1A6FNqgx
7S2wwZ1OgPJ7CZh8aqX7fyHIv/UGWjAVCr982jzXqHQgUeOazI6VTASw6PltM9tVMlg7ZCgHAaCW
8jJpg2JHsMKVZYSpMbPoLDJoMTpA/sVyjSbY6X1HoAJTkebEtd7r98FAoobGZZodIhNJHhJVDKbr
EwzUUuoCUMaRSwwBKsnqMi8Xs3r2OivmL3juQSzAgaNfw+Uix2Urt49REXn5RxeYSjrTeScF/QEA
06qDAqMewfWTXj/NpzQu2ewgmknoJfHAyIO0oqxI8hF7VWlVVW05r9tq5h3uvO/bFBiAEw7FF1ku
N0UfsVXyBaCkTkW/AO1JA7opRsmr/CUMRZ8buCOd35gUfNVK17hcmeiCTCRWMgF9RFyjl4PeJ5Ce
VngIcL0BKHrWWbV5Fbdh6G0F1brzJ8VfloBgKzsOwloMpmEIKLfSPSGUqCylcbEycQ1gRDnRMMZJ
RBCjRCkRxTWk/do1TishGEzGrTw/AS0T4KYENJ/QVEB5LL+EcyvJr3yOtYZY41ZgemN3xE7/RjUu
OA+TS57hABojz7kzK18hlId2M9/MVxlVYKu3eVYUTXuhRK/ivcXcxSv3MPDF8FRgaVwusCaJuC7j
grkR+QnhNx9vg9UXRsW0T01+kf+FJsbkJOZOpLDmC2JcYLNmHEaoxSga19+8p1zNsTQuEmKTgSG2
+2jR+sTPMz/f3Apl4tBNEYbvA2ns6v7F4lMIHnj60aGKjGEu9FXx6DJmWqQqze0fnbEttykZZ6KR
99VY7jwU48J41gq0pQDTuBhARd4RlA4MXxyWIUbpI8S0sMUgoweW4TH8QDq7MdXaD3j0mhFtGn6o
SxoSs4I7e/O2XrRpVvRjn8LnjwawmR9UZF2Klt5gtYOzvD5dB9gmJWtonCcCYcTRaLnzXQrCTBxM
GJmQBjopZCVaAJY8wvsWf8OONAAKY8ZYB4V9AKgwo6N3Vsxe6qaao1WJF72gjo3vhSk5mjy0LQ2y
/MV+CbA1V0ZZTOMMYHKT7It8v2Ackwoza7NZuLBgBsOL2HHBy2MG7tCI4sk/2A9UnqXQmYsRY5J7
bZZ6QJXz16atn+HjE0MlDCiiJ9vYrjHJvB26sls7oGBwYyrj9ZGV2SwGmuHdGpi5EnD5IriymMYZ
cCXXiFYmVmOUP6HwG2awZGI444livzwGknDAFqaVz7CfUeEEV5Qg0S/hBRlp2uIPIkAtOrTG9x0K
wBamPUwLdP3izdRpvSSwzdq+95tqUSiWI3VkNMRZtDMBZLAxA8D8AjZlMY3z5VOT/IMxwRuWWj+a
zwOMWeb3WvjdgtZ3qQfDIvpkfdmHoMI3EgiY4oxnKnTpDnlZS9G3T7hORd9rTQJp2OZJXpfZclG1
9UPdNTNfjS7Rwc5nVY3cgeF3rI8eYAAaVpmh5vU1izk1OzSOKf18LyCkH1w+SL+UiSGtN5bJpLmX
f5RTGd6Ci59hW/0jk2Kr/IMDyDkV2CrJusKNjRsHg/4nAV88pujmzbIa05OzunqpKM+qO+6ZmmHM
HudVyebaPidZmPXS0Z6FSeFhGFNuZhRjg1ueRr/ebCuLqUzU+JbzJxa67L9gg4LlXp5NW5gYVF76
8Rh/7BdwRlG0t/x7y1ZgI7BFm+f+GWYdavs0zelzZTUrZouqrmYMqgagqmcY+MKMip+9KvnI0q4D
uEKHO7fbw+iQVdGDbxUB0AhcwmLe5FCzQ+No+dS6lT74UpGXfqnPp4oq521L6ZRPGWvhMYSfi/YG
1cRWoEhj/XenXcw6kn6p4T3VPb2BhliqqglUBKaybuuSAFXWTV3yYhieEG5KfyCcnAfsV5oBWJjH
AotJx7uwWEd5WOjDEibjTuJkF7NDZaLGdgUYGhlY+g1+r0XLewRTdvxkIxOvlM74IITJTo88oL5Y
rPklqPBDmGxkYOEX2KiPQtuGyMIBO9OyrCDKLJdlPk0E82KYVgCV+/b5EkeYho2209E7q5H8HOYG
Fst0E9CQg62PlxxodqhM1FjLpzaln3f9VlPAcP3CrsCGV0bj0AN2/Xjn+viZlb4TqDaAhdn80PKX
pE52T9sBSM7SroUG9YcWZHwiSIthxiaXQwxkUUy3AlgeDopr1gH2nsXCUae7mB0qEzV2kX5+rmrd
pJB8StZDe4MiE4NC1kRb36606qT4FqjeMRZJwdWxJBb+Amk1nLKQJdPYPRbEwHLvV0ONfMkmJn/8
Dkby5YzgsPPiLYtJPubB1n9hdhwgE7Umpla67K8wspTT51PenJCir5wiwocZGONPCIk+t9L3AtUG
sFw00geRdZjwijGtbxyOKKH8Ci3zmLnCMFew3gGwPgCtA9N0vDyGd13w+ugmACywWOFXSpfNtLBz
YrL3ZscxZaKy2L1Z6UPCJoVtVyyVrc7ASrIWzzXvVY9t6KKItrHVXgdpe3Q6bpfFoL61GEYhjWnw
iGK/BgySXmatxm70SzgxFdwPcqSpH8tnJhvWxvNXLManMpJcrItJIm43O/aRiVoTUytd8inebmva
dM2kmHav45QQrk3Zna30g0D1EbiAWidPI15tAMyNwF3CQ18pn201mlGYzAOst3zeFctEdF60YVln
FnIxD6QvzY6dZaLWxNRKD1Y6mEoOPeiSNSs9SD8YFOhU38dK/xaoPmSuWJpwPwEYTw4n2G7DLDbw
3ovRy0Tc5YgebKthgNFrz179CmBvzI79ZKLWxBRU61b65Pq9s9KnI3dWJsVuVvpRQPUWXPjHBsBw
qFUc8VdlgtgNmLdyURInVtZJs0z8kMU6Btn6BqcjyEStiamVHgYRe6lP2eaNlY5jdjAv2Pnzgne2
0o8Kqs8A5kEmU78jP3y82YnnH3dksY/MjkNlotbEVPoFK5270mXg1p8MAulXTFY6HwBn9rPSTwaq
nVnMy8StLPaB2bGnTNSamMbaQGIcnD8/O5X4oducD4ibrHQZTNzLSj8bqLYBTOjsUxbbwezYJhO1
JqbS781AIlw/GxpoZZe6L/iKUSGjHp6ldrfSfwRUW2Tit8wOrYlpfCH9NgYS0UWR+oHEdBpI9Kcs
4jxgGBTmTVe6i9yu2yyTS/kX/7bZoTUxjc/j7UBiFwYS83DKIh9PmvHh3KkV6ccmhbfSdz2Z/qJA
9U2zQ2tiGp9Lvy8HEnOZ8p0GEsOUL8s/WOlj2NdyMUbFOcwOrYlpbGWqjS4KPvRtp4FEP+W710LY
5Nr+62hNTGXiIVb62y6KYw0k3gSovikTtSZ2dwpwpy6KgwcSbw5U+8pErYndXz4V7dZFcfBA4s2D
ahvAhM60JnZv0m/XLopDBhLvClRbZKLWxO6Drc7WRXF3oDq62aE1seuw0s/YRXH3oPqm2aE1seuQ
fmftolBQfcPs0JrY1cRZuygUVOeSiVdaE7tqs+OHuigUVOeRibdVE7s+Fjt4rfMhXRQKqjPIxCut
id2E2XHqtc4Kqh8CmNDZFdXEbsjsOPVaZwXVz8vEy6+J3YrZcaa1zgqqazc7zlcTu4kG4HOsdVZQ
XbfZcbaa2A01AJ98rbOC6orNjjPUxG6nAfiMa50VVPciE7UB+L2Vvlrr7FnqOGudFVT3IRPvuwHY
f++qK/3DgcTmWGudFVR3IBO1AXjKpZw3GzYGEln6pavWJDYpvrHWWUF1BwATOrvTBuCV6xdYarRG
XD9fnyIwpXWecFuSb01CPnX4WmcF1X3JxHtrAF5TgN7t83NTWO7Cw4geUDw7lRXVKp9KD17rrKBS
mXirDcCxY6/OBVBhXH4FKJtQDpWiFakq0nxZZAWuytenmKlkfsoczUpXUKnZcdUNwF4imjDyhIPd
jYmRR02AygRMiyKnKysXzFQZGxXSlQ6TAkft4tfEUt9SptL4UbPjp2piYDE3SUQ5dxBsA7cvtWlV
JBmBqHgp6Zrls5cyn72WxYzAVS6zrECrEhd9RfqZcZWmqfzTuCiz45w1McjCIZ3koDco4PZlSVoB
TPO8fJ4Xs6d5MX+alw/Ps4KBtczTgpnKBulnpPn2WNJPQaVxpQ3AwlziHo6W8U/5FHr8IPvKvHx5
LB/+/Jr9+vM4e/zzUD48zcoHMBYkIPZR8PwU5V4DZOMxpZ+CSuP4ZseJa2JeGrI89K6hQe0K4EgJ
VMijZnnxQmD69/f89//9evj953H+C2xFoCqXnFOhM90SoIw5KpgUVBqnMDvOVBMDWzFjwY5PAqhg
PsDtI/C8zsvHp98EKALV/xOo/iVQvRb5bOnHPXpjvZUeHVf6Kag0jmp2nKUBWBiLpGPHLiHAKqCK
HWpPxEI1gWoxKx5eHuePT4/z308PxFQzBlUp0m9tfurY0k9BpfFzMvFAs4M+zgOr0c+kDCoen4od
8iTUoGCdz4r5Yl7OXwEmev0K6SdLXrg1iXOpU7BUFEWRLrTXOHowyFCcHX3fg3RCSEe6W7GY29/s
SMFQABp9T0oSkuUf+xQAh7EDQINWpCIvKpJ7leRRhWxNwqnzYXQ+tmKlxwoqjSsFWCTMFYX1lJ7F
Iq41AWTjxGLmK7MDuRh/7MEHIPLvIljBIsdoPDrQsSATQMqzrEks76HoVoAyk/Q7NqAUVBo/B7DP
WcxsAOyNTCQQ2SG8JobjDgtiPRAjgYpAYgcAB2YEL8hMkD8lABM+P5waUAoqjYtjsW0yEfdwEc/F
0q4k4yA8voF2JQCItyjZkbsmDFqY7Dh1T5wQUAoqjctlsWiLTIzcNCHMn3cyBi8TvCbMVWH613mw
uXMASkGlcbVmRwCVfNWtPdC+49zv/vOTvGKbnwFQCiqNq87FBFTu7XP8Fkj8+hxgUlBp3ATIvoiz
AklDQ0NDQ+N64n8CDACcq5PfeJcmOwAAAABJRU5ErkJggg==" transform="matrix(0.24 0 0 0.24 138.9883 88.3154)">
				</image>
				<g>
					<g>
						<polygon fill="#FFFFFF" points="157.078,109.557 161.52,94.543 160.916,94.367 156.475,109.375 						"/>
						
							<rect x="159.538" y="104.125" transform="matrix(-0.2837 0.9589 -0.9589 -0.2837 314.995 -26.4201)" fill="#FFFFFF" width="15.655" height="0.627"/>
						
							<rect x="150.427" y="91.675" transform="matrix(-0.9605 -0.2782 0.2782 -0.9605 267.7311 237.0013)" fill="#FFFFFF" width="0.512" height="15.653"/>
						<path fill="#FFFFFF" d="M179.678,116.244c0.904,0.268,1.854-0.25,2.125-1.156l3.471-11.727
							c0.268-0.91-0.248-1.859-1.154-2.127l-6.467-1.916l-4.441,15.012L179.678,116.244z"/>
						<path fill="#51754A" d="M146.312,90.045c-0.906-0.268-1.855,0.248-2.125,1.156l-3.471,11.727
							c-0.268,0.906,0.248,1.861,1.154,2.127l6.346,1.881l4.441-15.012L146.312,90.045z"/>
						
							<rect x="146.987" y="96.672" transform="matrix(-0.2838 0.9589 -0.9589 -0.2838 295.3254 -19.1445)" fill="#768E6A" width="15.651" height="8.099"/>
						
							<rect x="155.353" y="99.15" transform="matrix(-0.2838 0.9589 -0.9589 -0.2838 308.4501 -23.9809)" fill="#93A487" width="15.656" height="8.099"/>
						
							<rect x="163.721" y="101.627" transform="matrix(-0.2837 0.9589 -0.9589 -0.2837 321.552 -28.8435)" fill="#C7CFC0" width="15.655" height="8.098"/>
					</g>
				</g>
			</g>
		</g>
	</g>
	<rect x="172.357" y="42.921" fill="#CEB7D7" width="8.523" height="8.521"/>
	<rect x="139.672" y="31.184" fill="#93A487" width="8.523" height="8.52"/>
	<rect x="161.012" y="39.603" fill="#E8B462" width="3.551" height="3.549"/>
	<rect x="156.428" y="51.575" fill="#51754A" width="3.553" height="3.551"/>
	<rect x="144.426" y="44.365" fill="#4E74B7" width="3.551" height="3.55"/>
	<rect x="128.871" y="42.684" fill="#C7372C" width="3.553" height="3.55"/>
</g>
</svg></router-link>
        <div class="header__list">
          <router-link class="header__link" to="/entreprise">Présentation</router-link>
          <router-link class="header__link" to="/prestations">Nos prestations</router-link>
          <router-link class="header__link" to="/references">Nos références</router-link>
          <router-link class="header__link" to="/contact">Contact</router-link>
        </div>
        <div class="menu-responsive">
          <span class="burger"></span>
          <span class="burger"></span>
          <span class="burger"></span>
        </div>
        <div class="responsiveMenu">
          <svg class="close" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"/></svg>
          <ul>
            <li><router-link class="header__link" to="/entreprise">Présentation</router-link></li>
            <li><router-link class="header__link" to="/prestations">Nos prestations</router-link></li>
            <li><router-link class="header__link" to="/references">Nos références</router-link></li>
            <li><router-link class="header__link" to="/contact">Contact</router-link></li>
          </ul>
        </div>
      </div>
      
    </div>
    <transition name="fade" mode="out-in">
          <router-view/>
      </transition>

    <div class="footer">
      <div class="footer__container">
        <div class="credits">Lacombe - Tous droits réservés / 2019 • <router-link class="mentions-legales" to="/legal">Mentions Légales</router-link></div>
        <div class="footer__list"><router-link to="/contact">Contact</router-link></div>
      </div>
    </div>
	<cookie-law theme="dark-lime" message="Nous utilisons des cookies afin de garantir les services proposés sur notre site et d’améliorer l’expérience de nos utilisateurs. Les cookies sont des données qui sont stockées sur votre ordinateur ou sur tout autre appareil." buttonText="J'accepte" ></cookie-law>  
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
export default {
  components: { CookieLaw },
  metaInfo: {
      meta: [
      { charset: 'utf-8' }
    ]
    },
}

</script>

<style lang="scss">

$header: 120px;

body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-size: 100%;
  position: relative;
}

.Cookie--dark-lime .Cookie__button {
        padding: .500em 2.000em !important;
        border-radius: 4px !important;
    }

	
#app {
  font-family: 'Open Sans', sans-serif;
}

#header {
  width: 100%;
  height: $header;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  box-sizing: border-box;

  .header__container {
    width: 1400px;
    height: $header;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center; 
  }

  .logo {
    width: 250px;
    height: 190px;
  }

  .header__list {
    width: 50%;
    display:flex;
    justify-content: space-around;
    align-items: center;
  }
}

.footer__list{
	text-transform: uppercase;
}
.header__link {
    font-weight: 600;
    text-decoration: none;
    font-size: 18px;
    color: black;
    &.router-link-exact-active {
      color: #3A633D;
    }
  }

.menu-responsive {
  display: none;
}

.footer {
  width: 100%;
  height: 60px;
  background-color: #3A633D;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  box-sizing: border-box;
  margin-top: 20px;
  color: white;
  bottom: 0;

  .footer__container {
    width: 1400px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 200;
    font-size: 16px;
    margin: 0px 12px;

    a {
    text-decoration: none;
    color: white;
  }

  .mentions-legales {
	border-bottom: 1px solid white;
}

  }
}

.fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.4s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }

    .responsiveMenu {
      position: absolute;
      width: 100%;
      top:0;
      left: 0;
      height: 100vh;
      background-color: #3A633D;
      opacity: 0.9;
      color: white;
      z-index: 1000;
      padding: 64px;
      box-sizing: border-box;
      animation: menuT .8s cubic-bezier(0, 0, 0.06, 1);
      display: none;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        list-style: none;
      }

      ul li {
        font-weight: 400;
        font-size: 21px;
        height: 10vh;
        text-transform: uppercase;
        color: white;

        .header__link {
          color: white;
        }
      }
}

.close {
  fill: white;
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 1001;
  cursor: pointer;
}

.disablescroll {
  overflow: hidden;
}

    .active {
      display: flex;
    }

  @keyframes menuT {
  0% {
    transform: translateX(1400px);
  }

  100% {
    transform: translateX(0px);
  }
}

  @media (max-width: 1300px) {
    #header {
      a {
      font-size: 16px;
    }

	.logo {
    width: 200px;
    height: 140px;
  }
    }

    .footer {
      .footer__container {
        width: 100%;
        font-size: 12px;
  }
}
}

@media(max-width: 850px) {
  #header {
    .header__list {
      display: none;
    }
  }

  .menu-responsive {
    display: flex;
    flex-direction: column;
    cursor: pointer;
	margin-right: 8px;

    span {
      width: 20px;
      height: 3px;
      margin-bottom: 2px;
      background-color: black;
      border-radius: 16px;
    }
  }
}

@media(max-width: 600px) {
  .footer {
  width: 100%;
  height: 60px;
  background-color: #3A633D;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  box-sizing: border-box;
  color: white;

  .footer__container {
    font-size: 10px;

  }
}
}
</style>
